import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModalLoader from '../../../components/common/ModalLoader';
import IntlMessages from '../../../helpers/IntlMessages';
import { IUser } from '../../../interfaces/userInterface';
import userService from '../../../services/userService';

export const MyPageEmployeerInformation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IUser>();

  const { id } = useParams<{ id: string }>();

  const fetchDataById = async () => {
    setIsLoading(true);
    userService
      .GetUserById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <Grid mt={2} container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Typography variant="h3">
          <IntlMessages id="heading.company-information" />
        </Typography>
      </Grid>
      <Grid mt={2} container direction="row" justifyContent="center" alignItems="stretch" spacing={3} paddingBottom={3}>
        <Grid item sm={12} md={6} className="padding-bottom">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.workerId" />
              <Grid item xs={12} md={6}>
                {editItem?.workerID}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.employeer" />
              <Grid item xs={12} md={6}>
                {editItem?.employeer}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.managername" />
              <Grid item xs={12} md={6}>
                {editItem?.managerName}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.id06" />
              <Grid item xs={12} md={6}>
                {editItem?.iD06Date}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.employmentdate" />
              <Grid item xs={12} md={6}>
                {editItem?.employmentDate}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.profession" />
              <Grid item xs={12} md={6}>
                {editItem?.professionId}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.driverlicens" />
              <Grid item xs={12} md={6}>
                {editItem?.driverLicensId}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.language" />
              <Grid item xs={12} md={6}>
                {editItem?.languageId}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.sizeupperbody" />
              <Grid item xs={12} md={6}>
                {editItem?.sizeUpperBody}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.sizepants" />
              <Grid item xs={12} md={6}>
                {editItem?.sizePants}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <IntlMessages id="form.sizeshoe" />
              <Grid item xs={12} md={6}>
                {editItem?.sizeShoe}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default MyPageEmployeerInformation;
