import IntlMessages from '../../../helpers/IntlMessages';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IUser } from '../../../interfaces/userInterface';
import userService from '../../../services/userService';
import { useParams } from 'react-router-dom';
import ModalLoader from '../../../components/common/ModalLoader';
import { GeneralContext } from '../../../App';
import { NotificationType } from '../../../components/common/NotificationBar';
import MyPageContactInformation from './myPageContactInformation';
import MyPageImage from './myPageImage';

const MyPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IUser>();
  const appContext = useContext(GeneralContext);
  const { control, handleSubmit, reset, watch, setValue } = useForm<IUser>();

  const { id } = useParams<{ id: string }>();

  const fetchDataById = async () => {
    setIsLoading(true);
    userService
      .GetUserById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fileSizeHandler = async (e: any) => {
    let fileSize = e.target.files[0].size;
    let maxFilesize = 200000;
    if (fileSize > maxFilesize) {
      reset({ profilePicture: undefined });
      appContext.ShowNotification(NotificationType.error, 'File is to big, try a smaller one! Max file size is 20000kb');
    } else {
      let logodata = await writeFileToByteArray(e.target.files[0]);
      setValue('profilePicture', logodata);
      onSubmit(watch());
    }
  };

  const writeFileToByteArray = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async (data: IUser) => {
    setIsLoading(true);
    if (editItem?.id) {
      let updatedItem = await userService.EditUser(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
    }
    appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    setTimeout(() => setIsLoading(false), 1000);
    setEditItem(data);
    setIsEditing(false);
  };

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  return (
    <>
      <ModalLoader isLoading={isLoading} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mt={2} container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Typography variant="h3">
            <IntlMessages id="heading.user-information" />
          </Typography>
        </Grid>
        <Grid mt={2} container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <MyPageImage watch={watch} control={control} fileSizeHandler={fileSizeHandler} />
          <MyPageContactInformation control={control} setIsEditing={setIsEditing} isEditing={isEditing} />
        </Grid>
      </form>
    </>
  );
};

export default MyPage;
