import { NotificationType } from '../components/common/NotificationBar';
import { IAbsence } from '../interfaces/absenceInterface';

export interface IGeneralState {
  absences: IAbsence[];
}
export interface IGeneralContext {
  general: IGeneralState;
  setGeneralState: React.Dispatch<React.SetStateAction<IGeneralState>>;
  ShowNotification(notificationType: NotificationType, notificationMessage: any): void;
}

export const initialGeneralContext: IGeneralContext = {
  general: {
    absences: [],
  },
  setGeneralState: () => {},
  ShowNotification: (notificationType: NotificationType, notificationMessage: any) => {},
};
