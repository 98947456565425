import { IAbsence } from '../interfaces/absenceInterface';
import { ICompany } from '../interfaces/companyInterface';
import { IMedicalInsurance } from '../interfaces/medicalinsuranceInterface';
import { IProject } from '../interfaces/projectInterface';
import { IRating } from '../interfaces/ratingInterface';
import { IUser } from '../interfaces/userInterface';
import { IVacation } from '../interfaces/vacationInterface';
import { IWorkedHours } from '../interfaces/workedHoursInterface';
import { IProfileImage } from '../interfaces/profileImageInterface';

export const defaultLocale = 'sv';
export const localeOptions = [
  { id: 'sv', name: 'Svenska', direction: 'ltr' },
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'nb', name: 'Norwegian', direction: 'ltr' },
  { id: 'pl', name: 'Polish', direction: 'ltr' },
  { id: 'ru', name: 'Russian', direction: 'ltr' },
];

export const IUserValues: IUser = {
  title: '',
  id: '',
  workerID: 0,
  profilePicture: undefined,
};

export const ICompanyValue: ICompany = {
  title: '',
  id: '',
  country: '',
  companyImage: '',
  defaultApproverAndNotificationReceiverIdCollection: [],
};

export const IAbsenceValue: IAbsence = {
  userId: '',
  title: '',
  id: '',
  workerID: 0,
  workedHours: 0,
  comment: '',
  companyId: '',
  created: undefined,
  start: undefined,
  end: undefined,
  approvedRejectDate: undefined,
  approverId: '',
  modified: undefined,
  selectedCauseId: 0,
  desicioncomment: '',
  approvers: '',
  status: 0,
  projectNumber: 0,
  approverComment: '',
};

export const IMedicalInsuranceValue: IMedicalInsurance = {
  id: '',
  userId: '',
  fullName: '',
  workerID: 0,
  companyId: '',
  created: undefined,
  contentType: '',
  fileName: '',
  absenceItemId: '',
  fileContent: '',
};

export const IProfileImageValue: IProfileImage = {
  id: '',
  companyId: '',
  userId: '',
  workerId: 0,
  profileImageFile: '',
};

export const DefaultVacation: IVacation = {
  paidVacation: 0,
  companyId: '',
  id: '',
  savedVacation: 0,
  unPaidVacation: 0,
  workerId: 0,
};
export const IWorkedHoursValue: IWorkedHours = {
  id: '',
  title: '',
  workerId: 0,
  hours: 0,
  dateStamp: undefined,
  dateStampMonthAndYear: '',
  companyId: '',
  projectNumber: 0,
};

export const IRatingValue: IRating = {
  id: '',
  companyId: '',
  workerId: '',
  averagepoints: 0,
  title: '',
  rank: 0,
  rankProject: 0,
  dateStamp: undefined,
  dateStampMonthAndYear: '',
};
export const DefaultSelectedProject: IProject = {
  address: '',
  companyId: '',
  end: undefined,
  id: '',
  postCity: '',
  postNumber: '',
  projectLeaderId: '',
  managerId: '',
  projectName: '',
  projectNumber: 0,
  start: undefined,
  supervisorId: '',
};
