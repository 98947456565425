import { IUserProject } from '../interfaces/userProjectInterface';
import { GetUserWithValidToken } from '../helpers/Utils';

export class UserProjectService {
    GetAllUserProjects = async () => {
        const user = await GetUserWithValidToken();
        const response = await fetch("/project/getallcompany/",{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IUserProject[] = await response.json();
        return responseData;
        
    } 

    public async GetAllAdmin() {
        let url = `/userproject/GetAllAdmin`;
        const user = await GetUserWithValidToken();
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (response.ok) {
          let responseData = await response.json();
          return responseData;
        } else {
          console.log();
          return [];
        }
      }
    GetAllUserProjectsByCompany = async (companyId: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`userproject/getallcompany/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IUserProject[] = await response.json();
        return responseData;
    } 
    GetAllMyUserProjects = async (userId: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`userproject/getallmy/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        return response;
    }
    GetUserProjectById= async (id: string | undefined) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`/userproject/getbyid/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IUserProject = await response.json();
        return responseData;
    } 

    Create = async (userProject: IUserProject) => {
        const user = await GetUserWithValidToken();
        const response = await fetch('/userproject/create', {   
            method: 'POST',       
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            },
            body: JSON.stringify(userProject)
        });
        return response;
    }
    Update = async (project: IUserProject) => {
      const user = await GetUserWithValidToken();
      const response = await fetch("/userproject/update", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}` 
          },
          body: JSON.stringify(project)
      });
      if(response.ok){
        return await response.json();
      }
      return null;
    }  
    Delete = async (id: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch("/userproject/delete/" + id, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        return response;
      };
}
const userprojectService = new UserProjectService();

export default userprojectService;