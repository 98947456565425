import { GetUserWithValidToken } from '../helpers/Utils';
import { IWorkerPlannerData } from '../interfaces/workerPlannerDataInterface';

export class WorkerPlannerDataService {
  GetAllProjectsByWorkerId = async (workerId: number) => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/workerplannerdata/getprojectsbyworkerid/${workerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    let responseData: IWorkerPlannerData[] = await response.json();
    return responseData;
  };

  GetAllUsersOnProject = async (projectNumber: number) => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/workerplannerdata/getusersonproject/${projectNumber}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response;
  };
}
const workerPlannerDataService = new WorkerPlannerDataService();

export default workerPlannerDataService;
