import * as React from 'react';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import IntlMessages from '../../../../helpers/IntlMessages';
import Paper from '@mui/material/Paper';
import { styled, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { IAbsence } from '../../../../interfaces/absenceInterface';
import absenceService from '../../../../services/absenceService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ModalLoader from '../../../../components/common/ModalLoader';

const ShowAllHistoricAbsences = () => {
  const [Absences, setAbsences] = useState<IAbsence[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    absenceService
      .GetAllAbsencesAdmin()
      .then((responseData) => {
        setAbsences(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5390d9',
      color: theme.palette.common.white,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.white,
    },
  }));

  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <Typography variant="h6">
        <IntlMessages id="heading.historicabsencetoreview" />
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <IntlMessages id="form.status" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.employee" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="absence.cause" />
              </StyledTableCell>
              <StyledTableCell className="hidden-xs" align="left">
                <IntlMessages id="form.workerId" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.start" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.end" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Absences
              ? Absences.filter((x) => x.status >= 1 && x.end !== null)?.map((item, i) => (
                  <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{item.status === 2 ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}</TableCell>
                    <TableCell align="left">{item.title}</TableCell>
                    <TableCell align="left">{<IntlMessages id={`absence.status-${item?.selectedCauseId}`} />}</TableCell>
                    <TableCell className="hidden-xs" align="left">
                      {item.workerID}
                    </TableCell>
                    <TableCell align="left">{dayjs(item.start).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="left">{item.end !== null ? dayjs(item.end).format('YYYY-MM-DD') : null}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShowAllHistoricAbsences;
