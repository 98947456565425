import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang/index';

// import registerServiceWorker from './registerServiceWorker';
import './styles/custom.css';

import { createRoot } from 'react-dom/client';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <BrowserRouter basename={baseUrl ?? ''}>
      <IntlProvider locale="sv-SE" messages={AppLocale.sv.messages}>
        <App />
      </IntlProvider>
    </BrowserRouter>
  );
}

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
// registerServiceWorker();
