export default function () {
  return [
    {
      title: 'topbar-nav.absence-start',
      children: [
        { name: 'topbar-nav.newabsence', to: '/app/absence/newabsence', roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'] },
        { name: 'topbar-nav.newsickleave', to: '/app/absence/newsickabsence', roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'] },
        { name: 'topbar-nav.myabsence', to: '/app/absence/myabsence', roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'] },
      ],
      roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'],
    },
    {
      title: 'topbar-nav.project-start',
      children: [
        { name: 'topbar-nav.myprojects', to: '/app/project/myprojects', roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'] },
        { name: 'topbar-nav.project', to: '/app/project', roles: ['Admin'] },
        { name: 'topbar-nav.userproject', to: '/app/userproject', roles: ['Admin'] },
      ],
      roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'],
    },
    // {
    //   title: 'topbar-nav.workerplanner-start',
    //   children: [{ name: 'topbar-nav.workerplannerdata-overview', to: '/app/wpd' }],
    // },
    {
      title: 'topbar-nav.timereporting-start',
      children: [{ name: 'topbar-nav.timereport-overview', to: '/app/timereporting', roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'] }],
      roles: ['Admin', 'Worker', 'ProjectLeader', 'Manager', 'Supervisor'],
    },
    {
      title: 'topbar-nav.administration-start',
      children: [
        { name: 'topbar-nav.allusers', to: '/app/admin/user/allUsers', roles: ['Admin'] },
        { name: 'topbar-nav.registeruser', to: '/app/admin/user/userform', roles: ['Admin'] },
        { name: 'topbar-nav.absencetoreview', to: '/app/admin/absence/absences', roles: ['Admin'] },
        { name: 'topbar-nav.historicabsence', to: '/app/admin/absence/historicAbsences', roles: ['Admin'] },
        { name: 'topbar-nav.allcompanies', to: '/app/admin/company/companies', roles: ['Admin'] },
      ],
      roles: ['Admin'],
    },
  ];
}
