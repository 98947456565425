import { GetUserWithValidToken } from "../helpers/Utils";



export class NotificationService {
    private controller = '/notification';

    GetLoginNotifications = async () => {
        const user = await GetUserWithValidToken();
        return await fetch(`${this.controller}/getloginnotifications/${user?.workerID}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
            }
        });

    }
}

export const notificationService = new NotificationService();