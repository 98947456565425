import { Grid, CircularProgress, Modal } from '@mui/material';
import React from 'react';

type ModalLoaderProps = {
  isLoading: boolean;
};

const ModalLoader = ({ isLoading }: ModalLoaderProps) => {
  return (
    <Modal open={isLoading}>
      <Grid style={{ minHeight: '100vh' }} direction="column" container justifyContent="space-evenly" alignItems="center">
        <Grid>
          <CircularProgress style={{ color: 'white' }} />
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ModalLoader;
