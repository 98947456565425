import { IFriendlyUser } from '../interfaces/authInterface';

export interface IUserState {
  user?: IFriendlyUser;
}
export interface IUserContext {
  userState?: IUserState;
  setUserState: React.Dispatch<React.SetStateAction<IUserState>>;
}

export const initialDataContext: IUserContext = {
  userState: undefined,
  setUserState: () => {},
};
