import { Grid, Modal, Card, CardHeader, Typography, CardContent, Button } from '@mui/material';
import React from 'react';
import IntlMessages from './IntlMessages';

type WarningModalProps = {
  text: any;
  onYesClick: any;
  onNoClick: any;
  yesText: any;
  noText: any;
  open: boolean;
};

const WarningModal = ({ text, onYesClick, onNoClick, yesText, noText, open }: WarningModalProps) => {
  return (
    <Modal open={open}>
      <Grid
        container
        borderRadius={2}
        style={{
          borderRadius: 2,
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<IntlMessages id="error.warning" />}></CardHeader>
            <CardContent>
              <Typography variant="h3">{text}</Typography>
              <Grid marginBottom={2} marginTop={2} container justifyContent="space-between">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onNoClick();
                  }}
                >
                  {noText}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    onYesClick();
                  }}
                >
                  {yesText}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default WarningModal;
