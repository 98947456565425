import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { GeneralContext, UserContext } from '../../../App';
import ModalLoader from '../../../components/common/ModalLoader';
import { NotificationType } from '../../../components/common/NotificationBar';
import WindowConfirm from '../../../components/common/WindowConfirm';
import { IFriendlyUser } from '../../../interfaces/authInterface';
import { IProject } from '../../../interfaces/projectInterface';
import IntlMessages from '../../../helpers/IntlMessages';
import projectService from '../../../services/projectService';
import userService from '../../../services/userService';
import { IUserProject } from '../../../interfaces/userProjectInterface';
import userprojectService from '../../../services/userProjectService';

const UserProjectForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>();
  const [userCollection, setUserCollection] = useState<IFriendlyUser[]>([]);
  const [projectCollection, setProjectCollection] = useState<IProject[]>([]);
  const [editItem, setEditItem] = useState<IUserProject>();
  const User = useContext(UserContext);
  const { control, handleSubmit, reset } = useForm<IUserProject>();

  const { id } = useParams();
  const appContext = useContext(GeneralContext);

  const fetchData = async () => {
    setIsLoading(true);
    Promise.all([projectService.GetAllProjectsByCompany(User.userState?.user?.companyId ?? ''), userService.GetAllUsers()])
      .then(async ([projects, users]) => {
        setProjectCollection(projects);
        setUserCollection(users);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const fetchDataById = async () => {
    setIsLoading(true);
    userprojectService
      .GetUserProjectById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    if (id) {
      fetchDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = async () => {
    setIsLoading(true);
    userprojectService
      .Delete(id ?? '')
      .catch((error: Error) => {
        console.error(error);
      })
      .then(() => {
        appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.deletesuccess" />);
        navigate('/app/userproject');
      });
  };
  const onSubmit = async (data: IUserProject) => {
    setIsLoading(true);
    if (id) {
      let updatedItem = await userprojectService.Update(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    } else {
      let updatedItem = await userprojectService.Create(data);
      if (!updatedItem.ok) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.savesuccess" />);
    }
    navigate('/app/userproject');
  };

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);
  return (
    <>
      <WindowConfirm
        isHidden={!showDeleteConfirm}
        noText={<IntlMessages id="button.abort" />}
        onNoClick={() => setShowDeleteConfirm(false)}
        onYesClick={onDelete}
        text={<IntlMessages id="info.delete-confirm" />}
        yesText={<IntlMessages id="button.delete" />}
      />
      <ModalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container marginTop={5} maxWidth="sm" borderRadius={3} justifyContent="center" className="container-fluid color-white">
          <Grid item xs={12} sm={12} marginTop={2}>
            <Typography>{id ? <IntlMessages id="form.edituserproject" /> : <IntlMessages id="form.userproject" />}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'projectId'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="id">
                    <IntlMessages id="form.projects" />
                  </InputLabel>
                  <Select size="small" error={!!error} label={<IntlMessages id="form.projects" />} value={value || ''} onChange={onChange}>
                    <MenuItem></MenuItem>
                    {projectCollection?.map((project, index) => (
                      <MenuItem key={index} value={project.id}>
                        {project.projectName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: '#FF1943' }}>{error ? <IntlMessages id={error.message} /> : null}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'userId'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="id">
                    <IntlMessages id="form.worker" />
                  </InputLabel>
                  <Select size="small" error={!!error} label={<IntlMessages id="form.worker" />} value={value || ''} onChange={onChange}>
                    <MenuItem></MenuItem>
                    {userCollection?.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {user.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: '#FF1943' }}>{error ? <IntlMessages id={error.message} /> : null}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          <Grid container justifyContent="space-between" marginBottom={2} marginTop={2}>
            {id ? (
              <Button onClick={() => setShowDeleteConfirm(true)} variant="contained" color="error">
                {<IntlMessages id="button.delete" />}
              </Button>
            ) : null}
            <Button onClick={() => navigate(`/app/userproject`)} variant="outlined">
              {<IntlMessages id="button.abort" />}
            </Button>
            <Button type="submit" variant="contained">
              {<IntlMessages id="button.save" />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default UserProjectForm;
