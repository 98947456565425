export interface INotificationState {
  isSick: boolean;
  sickDate?: Date;
  absenceId?: string;
}
export interface INotificationContext {
  notificationState: INotificationState;
  setNotificationState: React.Dispatch<React.SetStateAction<INotificationState>>;
}

export const initialNotificationContext: INotificationContext = {
  notificationState: { isSick: false, sickDate: undefined },
  setNotificationState: () => {},
};

export interface INotificationObjectResponse {
  isSick?: boolean;
  sickDate?: Date;
  absenceId?: string;
}
