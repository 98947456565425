import { Grid, Input, FormControl, Button } from '@mui/material';
import React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { IUser } from '../../../interfaces/userInterface';
import DefaultProfile from '../../../assets/DefaultProfile.jpg';
import EditIcon from '@mui/icons-material/Edit';

type MyPageImageProps = {
  watch: UseFormWatch<IUser>;
  control: Control<IUser, any>;
  fileSizeHandler: (e: any) => Promise<void>;
};

export const MyPageImage = ({ watch, control, fileSizeHandler }: MyPageImageProps) => {
  return (
    <Grid item sm={12} md={2}>
      <Controller
        name={'profilePicture'}
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <>
            <FormControl fullWidth>
              <Input id="fileUpload" style={{ display: 'none' }} inputProps={{ accept: 'image/png' }} type="file" onChange={fileSizeHandler}></Input>
              <img className="img" alt="" width={150} src={watch('profilePicture') ?? DefaultProfile}></img>

              <label htmlFor="fileUpload">
                <Button
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 100,
                    zIndex: 1,
                  }}
                  component="span"
                >
                  <EditIcon />
                </Button>
              </label>
            </FormControl>
          </>
        )}
      />
    </Grid>
  );
};
export default MyPageImage;
