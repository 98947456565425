import { Grid, Typography, TextField, Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { GeneralContext, UserContext } from '../../../App';
import ModalLoader from '../../../components/common/ModalLoader';
import { NotificationType } from '../../../components/common/NotificationBar';
import IntlMessages from '../../../helpers/IntlMessages';
import authService from '../../../services/authService';

const MyAccountPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appContext = useContext(GeneralContext);
  const methods = useForm();
  const user = useContext(UserContext);
  const { handleSubmit, control, watch } = methods;

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let friendlyUser = { userName: user.userState?.user?.email, password: data.password, newPassword: data.newPassword };
    let success: any = await authService.updatePasswordAsync(friendlyUser);
    if (success.statusText === 'OK') {
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
      setIsLoading(false);
    } else if (success) {
      appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
      setIsLoading(false);
    }
  };
  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} sm={12} marginTop={2}>
          <Typography variant="h3">
            <IntlMessages id="user.changepassword" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} marginTop={2}>
          <Controller
            name={'password'}
            control={control}
            rules={{
              required: 'error-required',
              pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message: 'error.incorrectpassword',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                error={!!error}
                helperText={error ? <IntlMessages id={error.message} /> : null}
                value={value?.toString() || ''}
                fullWidth
                size="small"
                type="password"
                label={<IntlMessages id="user.password" />}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} marginTop={2}>
          <Controller
            name={'newPassword'}
            control={control}
            rules={{
              required: 'error-required',
              pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message: 'error.incorrectpassword',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                error={!!error}
                helperText={error ? <IntlMessages id={error.message} /> : null}
                value={value?.toString() || ''}
                fullWidth
                type="password"
                label={<IntlMessages id="user.newpassword" />}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} marginTop={2}>
          <Controller
            name={'confirmPassword'}
            control={control}
            rules={{ required: 'error-required', validate: (x) => x === watch('newPassword') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                error={!!error}
                helperText={error?.message ? <IntlMessages id={error?.message} /> : null || error?.type === 'validate' ? <IntlMessages id="error.passworddoesnotmatch" /> : null}
                value={value?.toString() || ''}
                fullWidth
                type="password"
                label={<IntlMessages id="user.confirmpassword" />}
                size="small"
              />
            )}
          />
        </Grid>

        <Grid container justifyContent="right" marginBottom={2} marginTop={2}>
          <Button type="submit" variant="contained" color="primary">
            {<IntlMessages id="button.save" />}
          </Button>
        </Grid>
      </form>
    </>
  );
};
export default MyAccountPassword;
