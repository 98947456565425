// import { addLocaleData } from 'react-intl';
import enLang from './entries/en-US';
import svLang from './entries/sv-SE';
import nbLang from './entries/nb-NO';
import plLang from './entries/pl-PL';
import ruLang from './entries/ru-RU';

// import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'

// // This is optional but highly recommended
// // since it prevents memory leak
// const cache = createIntlCache()

// const intl = createIntl({
//   locale: 'fr-FR',
//   messages: {}
// }, cache)

const AppLocale = {
  en: enLang,
  sv: svLang,
  nb: nbLang,
  pl: plLang,
  ru: ruLang,
};
export const GetAppLocale = (key: string) => {
  switch (key) {
    case 'en':
      return enLang;
    case 'sv':
      return svLang;
    case 'nb':
      return nbLang;
    case 'pl':
      return plLang;
    case 'ru':
      return ruLang;
    default:
      return svLang;
  }
};
// addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.es.data);
// addLocaleData(AppLocale.enrtl.data);

export default AppLocale;
