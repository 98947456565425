import { IProject } from '../interfaces/projectInterface';
import { GetUserWithValidToken } from '../helpers/Utils';

export class ProjectService {
    private controller: string = '/project';
    GetAllProjects = async () => {
        const user = await GetUserWithValidToken();
        const response = await fetch("/project/getallcompany/", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IProject[] = await response.json();
        return responseData;
    } 
    GetAllProjectsByCompany = async (companyId: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`${this.controller}/getallcompany/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IProject[] = await response.json();
        return responseData;
    } 
    GetAllMyProjects = async (userId: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`${this.controller}/getallmy/${user.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        return response;
    }
    GetProjectById= async (id: string | undefined) => {
        const user = await GetUserWithValidToken();
        const response = await fetch(`/project/getbyid/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            } 
        });
        let responseData: IProject = await response.json();
        return responseData;
    } 

    Create = async (project: IProject) => {
        const user = await GetUserWithValidToken();
        return await fetch('/project/create', {   
            method: 'POST',       
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}` 
            },
            body: JSON.stringify(project)
        });
    }
    Update = async (project: IProject) => {
      const user = await GetUserWithValidToken();
      const response = await fetch("/project/update", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}` 
          },
          body: JSON.stringify(project)
      });
      if(response.ok){
        return await response.json();
      }
      return null;
    }  
    Delete = async (id: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch("/project/delete/" + id, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        return response;
      };
    GetProeject = async (id: string) => {
        const user = await GetUserWithValidToken();
        const response = await fetch("/project/" + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        return response;
      };
}
const projectService = new ProjectService();

export default projectService;