import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { TopMenuLayout } from './TopMenuLayout';

interface DefaultLayoutProps {
  children?: any;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  console.log(children);

  return (
    <>
      <TopMenuLayout />
      <Container sx={{ height: '100vh' }} maxWidth="lg">
        <Box>{children || <Outlet />}</Box>
      </Container>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
