import moment from 'moment';
import { defaultLocale, localeOptions } from '../constants/defaultValues';
import { IFriendlyUser } from '../interfaces/authInterface';
import authService from '../services/authService';

export const setCurrentUser = (usr: IFriendlyUser, generatedToken: string) => {
  usr.token = generatedToken;
  localStorage.setItem('portal-user', JSON.stringify(usr));
};

function hasTokenDateExpired(dateObj?: Date) {
  const now = moment();
  const date = moment(dateObj);
  if (date >= now) {
    return false;
  }
  return true;
}

export const getCurrentUser = () => {
  const usrValue = localStorage.getItem('portal-user');
  if (usrValue) {
    const user = JSON.parse(usrValue);
    if (!hasTokenDateExpired(user?.tokenExpiry)) {
      return user;
    }
    return null;
  }

  return null;
};

export const signOutCurrentUser = () => {
  console.log('signOutCurrentUser');
  localStorage.removeItem('portal-user');
};

export const GetUserWithValidToken = async () => {
  const usrValue = localStorage.getItem('portal-user') as string;
  let user: IFriendlyUser = JSON.parse(usrValue);
  if (!hasTokenDateExpired(user?.tokenExpiry)) {
    return user;
  }
  user = await authService.RenewToken(user);
  setCurrentUser(user, user?.token);
  return user;
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') && localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
        ? (localStorage.getItem('currentLanguage') as string)
        : defaultLocale;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentLanguage -> error', error);
    language = defaultLocale;
  }
  return language;
};

export const setCurrentLanguage = (locale: string) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentLanguage -> error', error);
  }
};
