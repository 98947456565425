export class apiHandler {
  public static AuthenticationResultStatus = {
    Success: 'success',
    Fail: 'fail',
  };
  public static error(message?: any): Error {
    return { ok: false, message };
  }

  public static success = (state?: any): Success => {
    return { ok: true, state: state };
  };
}

export interface Success {
  ok: boolean;
  state: any;
}

export interface Error {
  ok: boolean;
  message: any;
}
