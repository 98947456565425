import { GetUserWithValidToken } from '../helpers/Utils';
import { IAbsence } from '../interfaces/absenceInterface';
import { ICompany } from '../interfaces/companyInterface';

export class AbsenceService {
  AddAbsence = async (newAbsence: IAbsence) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/absence/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(newAbsence),
    });
    try {
      if (response.ok) {
        let data = await response.json();
        return data;
      } else {
        let test = await response.json();
        return test;
      }
    } catch (e) {
      console.log(e);
    }
  };
  UpdateAbsence = async (updateAbsence: IAbsence) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/absence/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(updateAbsence),
    });
    return response;
  };
  GetMyAbsences = async (userId: string) => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/absence/getallmy/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response;
  };

  GetAbsenceById= async (id: string | undefined) => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/absence/getbyid/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}` 
        } 
    });
    let responseData: IAbsence = await response.json();
    return responseData;
  } 

  GetAllAbsences = async (company: ICompany) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/absence/GetAllCompany', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(company),
    });
    return response;
  };

  public async GetAllAbsencesAdmin() {
    let url = `/absence/GetAllAdmin`;
    const user = await GetUserWithValidToken();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      let responseData = await response.json();
      return responseData;
    } else {
      console.log();
      return [];
    }
  }

  ApproveAbsence = async (absence: IAbsence) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/absence/approveabsence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(absence),
    });
    return response;
  };
  RejectAbsence = async (absence: IAbsence) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/absence/rejectabsence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(absence),
    });
    return response;
  };
  DeleteAbsence = async (id: string) => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/absence/delete/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response;
  };
}
const absenceService = new AbsenceService();

export default absenceService;
