import IntlMessages from '../../../../helpers/IntlMessages';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button, Card, CardHeader, Divider, CardContent, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import ModalLoader from '../../../../components/common/ModalLoader';
import { GeneralContext, UserContext } from '../../../../App';
import { NotificationType } from '../../../../components/common/NotificationBar';
import { useNavigate, useParams } from 'react-router-dom';
import { IAbsence } from '../../../../interfaces/absenceInterface';
import absenceService from '../../../../services/absenceService';
import moment from 'moment';
import WarningModal from '../../../../helpers/warningModal';

const AbsenceApprove = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IAbsence>();
  const appContext = useContext(GeneralContext);
  const [approved, setApproved] = useState(false);
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [approveReject, setApproveReject] = useState(false);
  const { id } = useParams();
  const { control, reset, watch } = useForm<IAbsence>();

  let navigate = useNavigate();

  const fetchDataById = async () => {
    setIsLoading(true);
    absenceService
      .GetAbsenceById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  const onSubmit = async (data: IAbsence) => {
    setIsLoading(true);
    if (editItem?.id && approved) {
      data.approvedRejectDate = new Date();
      data.status = 2;
      data.approverId = user.userState?.user?.id;
      data.approverComment = watch('approverComment');
      let updatedItem = await absenceService.UpdateAbsence(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    }
    if (editItem?.id && !approved) {
      data.approvedRejectDate = new Date();
      data.status = 1;
      data.approverId = user.userState?.user?.id;
      data.approverComment = watch('approverComment');
      let updatedItem = await absenceService.UpdateAbsence(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.savesuccess" />);
    }

    setTimeout(() => setIsLoading(false), 1000);
    reset();
  };
  const start = moment(editItem?.start).format('YYYY-MM-DD');
  const end = moment(editItem?.end).format('YYYY-MM-DD');

  return (
    <>
      <WarningModal
        open={open}
        noText={<IntlMessages id="button.undoedit" />}
        onNoClick={() => {
          setOpen(false);
        }}
        onYesClick={() => {
          onSubmit(editItem as IAbsence);
          setOpen(false);
          navigate(`/app/admin/absence/absences`);
        }}
        text={approveReject ? <IntlMessages id="absence.areyousureapprove" /> : <IntlMessages id="absence.areyousurereject" />}
        yesText={<IntlMessages id="button.save" />}
      />
      <ModalLoader isLoading={isLoading} />
      <Grid mt={2} container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
        <Grid item sm={12} md={8} className="padding-bottom">
          <Card>
            <CardHeader
              title={
                <>
                  {editItem ? (
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {editItem?.title} <IntlMessages id={`absence.status-${editItem?.selectedCauseId}`} />
                    </Typography>
                  ) : null}
                </>
              }
            ></CardHeader>
            <Divider />
            <CardContent>
              <Typography>
                <IntlMessages id="form.date-from" /> {start} <br />
                {editItem?.end ? (
                  <>
                    <IntlMessages id="form.date-to" /> {end} <br />{' '}
                  </>
                ) : null}{' '}
                {editItem?.workedHours !== 0 ? (
                  <>
                    <IntlMessages id="form.workedhours" /> {editItem?.workedHours} <br />{' '}
                  </>
                ) : null}{' '}
                <IntlMessages id="form.comment" /> {editItem?.comment}{' '}
              </Typography>
            </CardContent>
          </Card>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'approverComment'}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value?.toString() || ''}
                  fullWidth
                  size="small"
                  label={<IntlMessages id="form.approverComment" />}
                />
              )}
            />
          </Grid>
          <Grid marginTop={2} container direction="row" justifyContent="space-between">
            <Button
              onClick={() => {
                setApproved(false);
                setApproveReject(false);
                setOpen(true);
              }}
              variant="contained"
              color="error"
            >
              <IntlMessages id="button.reject" />
            </Button>
            <Button onClick={() => navigate(`/app/admin/absence/absences`)} variant="outlined">
              {<IntlMessages id="button.abort" />}
            </Button>

            <Button
              onClick={() => {
                setApproved(true);
                setApproveReject(true);
                setOpen(true);
              }}
              variant="contained"
              color="success"
            >
              <IntlMessages id="button.approve" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default AbsenceApprove;
