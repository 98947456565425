import dayjs from 'dayjs';
import { IFriendlyUser } from '../interfaces/authInterface';
import { apiHandler } from './apiHandler';

export class AuthorizeService {
  public getCurrentUser() {
    const usrValue = localStorage.getItem('portal-user');
    if (usrValue) {
      const user = JSON.parse(usrValue);
      if (!this.hasTokenDateExpired(user?.tokenExpiry)) {
        return user;
      }
      return null;
    }

    return null;
  }

  public GetUserWithValidToken = async () => {
    const usrValue = localStorage.getItem('portal-user');
    let user = JSON.parse(usrValue ? usrValue : '');
    if (!this.hasTokenDateExpired(user?.tokenExpiry)) {
      return user;
    }
    user = await authService.RenewToken(user);
    localStorage.setItem('portal-user', JSON.stringify(user));
    return user;
  };

  private hasTokenDateExpired(dateObj: Date) {
    const now = dayjs();
    const date = dayjs(dateObj);
    if (date >= now) {
      return false;
    }
    return true;
  }

  signIn = async (credentials: any) => {
    const response = await fetch(`/auth/loginasync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    });
    console.log(response);
    try {
      if (response.ok) {
        const data = await response.json();
        let user = data as IFriendlyUser;
        localStorage.setItem('portal-user', JSON.stringify(user));
        return apiHandler.success(user);
      } else {
        const errorResponse = await response.text();
        return apiHandler.error(errorResponse);
      }
    } catch (e) {
      return apiHandler.error(e);
    }
  };

  async RenewToken(user: IFriendlyUser) {
    const response = await fetch('/auth/renewtoken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    if (response.ok) {
      var respObj = await response.json();
      return respObj;
    }
    return null;
  }

  async signOut() {
    const response = await fetch('/auth/signoutasync', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    try {
      if (response.ok) {
        localStorage.removeItem('portal-user');
        return apiHandler.success('');
      } else {
        return apiHandler.error('Gick ej att logga ut');
      }
    } catch (e) {
      console.error(e);
      return apiHandler.error(e);
    }
  }

  async forgotPasswordAsync(email: string) {
    const response = await fetch(`/auth/forgotpasswordasync/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    try {
      if (response.ok) {
        return apiHandler.success();
      } else {
        const errorResponse = await response.json();
        return apiHandler.error(errorResponse);
      }
    } catch (e) {
      return apiHandler.error('');
    }
  }

  async registerUserAsync(credentials: any) {
    try {
      const response = await fetch(`/auth/registerUserAsync`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      if (response.ok) {
        return apiHandler.success();
      } else {
        const errorResponse = await response.json();
        return apiHandler.error(errorResponse);
      }
    } catch (e) {
      return apiHandler.error('');
    }
  }

  async validatePasswordTokenAsync(credentials: any) {
    try {
      const response = await fetch('/auth/validatepasswordtokenasync', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      var success = (await response.text()) === 'true';
      if (success) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async resetPasswordAsync(state: any) {
    try {
      const response = await fetch('/auth/resetpasswordasync', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });
      var textResponse = await response.text();
      if (textResponse === 'Password was reset') {
        return response;
      } else {
        return response.statusText;
      }
    } catch (e) {
      return e;
    }
  }

  async updatePasswordAsync(state: any) {
    try {
      const response = await fetch('/auth/updatepasswordasync', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });
      var textResponse = await response.text();
      if (textResponse === 'Password was Updated') {
        return response;
      } else {
        return response.statusText;
      }
    } catch (e) {
      return e;
    }
  }

  async updateEmail(state: any) {
    try {
      const response = await fetch('/user/updateemail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });
      var textResponse = await response.text();
      if (textResponse === 'Email was Updated') {
        return response;
      } else {
        return response.statusText;
      }
    } catch (e) {
      return e;
    }
  }

  async resetPasswordProtectedAsync(credentials: any) {
    try {
      const response = await fetch('/auth/resetpasswordprotectedasync', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      var textResponse = await response.text();
      if (textResponse === 'success') {
        return apiHandler.success();
      } else {
        return apiHandler.error(textResponse);
      }
    } catch (e) {
      return apiHandler.error('');
    }
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;
