import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GeneralContext, UserContext } from '../../../App';
import ModalLoader from '../../../components/common/ModalLoader';
import { NotificationType } from '../../../components/common/NotificationBar';
import IntlMessages from '../../../helpers/IntlMessages';
import userService from '../../../services/userService';
import cloneDeep from 'lodash/cloneDeep';
import { setCurrentUser } from '../../../helpers/Utils';
import { IFriendlyUser } from '../../../interfaces/authInterface';

const MyAccountEmail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appContext = useContext(GeneralContext);
  const methods = useForm();
  const userContext = useContext(UserContext);
  const { handleSubmit, control, watch } = methods;

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let friendlyUser = { userName: userContext.userState?.user?.email, newEmail: data.email };
    let success: any = await userService.updateEmail(friendlyUser);
    if (success.statusText === 'OK') {
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
      let newUserObj = cloneDeep(userContext.userState?.user as IFriendlyUser);
      newUserObj.email = data.email;
      userContext?.setUserState((prev) => ({
        ...prev,
        user: newUserObj,
      }));
      setCurrentUser(newUserObj, userContext.userState?.user?.token as string);
      setIsLoading(false);
    } else if (success) {
      appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} sm={12} marginTop={2}>
          <Typography variant="h3">
            <IntlMessages id="user.changeemail" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} marginTop={1}>
          <Controller
            name={'email'}
            control={control}
            rules={{
              required: 'error-required',
              pattern: {
                value:
                  /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$)|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))[0-9]{4}$|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))([7-9]0|[6-9][1-9])[0-9]{4}$/i,
                message: 'error.notvalidemail',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  size="small"
                  label={<IntlMessages id="form.newemail" />}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} marginTop={1}>
          <Controller
            name={'confirmEmail'}
            control={control}
            rules={{
              required: 'error-required',
              validate: (x) => x === watch('email'),
              pattern: {
                value:
                  /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$)|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))[0-9]{4}$|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))([7-9]0|[6-9][1-9])[0-9]{4}$/i,
                message: 'error.notvalidemail',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message ? <IntlMessages id={error?.message} /> : null || error?.type === 'validate' ? <IntlMessages id="error.notmatchingemails" /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  size="small"
                  label={<IntlMessages id="form.verifyemail" />}
                />
              </>
            )}
          />
        </Grid>
        <Grid container justifyContent="right" marginBottom={2} marginTop={2}>
          <Button type="submit" variant="contained" color="primary">
            {<IntlMessages id="button.save" />}
          </Button>
        </Grid>
      </form>
    </>
  );
};
export default MyAccountEmail;
