import * as React from 'react';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IntlMessages from '../../../../helpers/IntlMessages';
import { ICompany } from '../../../../interfaces/companyInterface';
import companyService from '../../../../services/companyService';
import Paper from '@mui/material/Paper';
import { styled, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ModalLoader from '../../../../components/common/ModalLoader';

let emptyCollection: ICompany[] = [];
const AllCompanies = () => {
  const [Company, setCompanies] = useState(emptyCollection);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    companyService
      .GetCompanies()
      .then((responseData) => {
        setCompanies(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5390d9',
      color: theme.palette.common.white,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.white,
    },
  }));

  let navigate = useNavigate();
  const addNewButton = () => {
    navigate('/app/admin/company/companyform');
  };
  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <Typography variant="h6">
        <IntlMessages id="company.allcompanies" />
      </Typography>
      <Button onClick={addNewButton}>
        <AddIcon />
        <IntlMessages id="button.addnew" />
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <IntlMessages id="form.name" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.country" />
              </StyledTableCell>
              <StyledTableCell className="hidden-xs" align="left">
                <IntlMessages id="form.logo" />
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Company
              ? Company?.map((item, i) => (
                  <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{item.title}</TableCell>
                    <TableCell align="left">{item.country}</TableCell>
                    <TableCell className="hidden-xs" align="left">
                      <img alt="Company logo" className="custom-company-logo" src={Boolean(item.companyImage) ? item.companyImage : require('../../../../assets/DefaultCompany.png')} />
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={() => navigate(`/app/admin/company/companyform/${item.id}`)}>
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllCompanies;
