import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import ModalLoader from '../../../../components/common/ModalLoader';
import { useParams } from 'react-router-dom';
import { IAbsence } from '../../../../interfaces/absenceInterface';
import absenceService from '../../../../services/absenceService';
import NewSickAbsence from '../../absence/newSickAbsence';
import NewAbsence from '../../absence/newAbsence';

const AbsenceForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<IAbsence>();
  const { id } = useParams();
  const { reset, watch } = useForm<IAbsence>();

  const fetchDataById = async () => {
    setIsLoading(true);
    absenceService
      .GetAbsenceById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  return (
    <>
      <ModalLoader isLoading={isLoading} />

      <Grid container marginTop={5} maxWidth="sm" borderRadius={3} justifyContent="center" className="container-fluid color-white">
        {watch('selectedCauseId') === 1 ? <NewSickAbsence /> : <NewAbsence />}
      </Grid>
    </>
  );
};

export default AbsenceForm;
