import React, { useEffect } from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
  children?: ReactNode;
}

const RootLayout: FC<BaseLayoutProps> = ({ children }) => {
  console.log('test:');
  console.log(children);
  useEffect(() => {
    document.body.classList.add('background');
    document.body.classList.add('no-footer');

    return () => {
      document.body.classList.remove('background');
      document.body.classList.remove('no-footer');
    };
  }, []);

  return (
    <>
      {children || <Outlet />}
      <div className="video-wrapper">
        <iframe title="background" src="https://player.vimeo.com/video/484377249?background=1&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameBorder="0"></iframe>
      </div>
    </>
  );
};

RootLayout.propTypes = {
  children: PropTypes.node,
};

export default RootLayout;
