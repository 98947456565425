import React, { createContext, useEffect, useState } from 'react';
import router from './router';
import { useRoutes } from 'react-router-dom';
import './styles/custom.css';
import { GetAppLocale } from './lang';
import { IntlProvider } from 'react-intl';
import { initialDataContext, IUserContext } from './context/userContext';
import { ILangContext, initialLangContext } from './context/languageContext';
import { IGeneralContext, initialGeneralContext } from './context/generalContext';
import { NotificationContainer } from './components/common/react-notifications';
import { getCurrentLanguage, getCurrentUser } from './helpers/Utils';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { NotificationBar, NotificationType } from './components/common/NotificationBar';
import { IUserState } from './context/userContext';
import { ILanguageState } from './context/languageContext';
import { IGeneralState } from './context/generalContext';
import { initialNotificationContext, INotificationContext, INotificationState } from './context/notificationContext';
import ThemeProvider from './theme/ThemeProvider';
import { notificationService } from './services/notificationService';
export const UserContext = createContext(initialDataContext);
export const LanguageContext = createContext(initialLangContext);
export const GeneralContext = createContext(initialGeneralContext);
export const NotificationContext = createContext(initialNotificationContext);
const App = () => {
  const [notificationState, setNotificationState] = useState<INotificationState>({ isSick: false, sickDate: undefined });
  const [userState, setUserState] = useState<IUserState>({ user: getCurrentUser() });
  const [langState, setLangState] = useState<ILanguageState>({ lang: getCurrentLanguage() });
  const [generalState, setGeneralState] = useState<IGeneralState>({ absences: [] });
  const [notificationType, setNotificationType] = useState(NotificationType.none);
  const [notificationMessage, setNotificationMessage] = useState<ReactJSXElement>();

  const ShowNotification = (notificationType: NotificationType, notificationMessage: ReactJSXElement) => {
    setNotificationMessage(notificationMessage);
    setNotificationType(notificationType);
  };
  const { lang } = langState;
  const currentAppLocale = GetAppLocale(lang);

  const fetchLoginNotifications = async () => {
    notificationService.GetLoginNotifications().then(async (response) => {
      if (response.ok) {
        let data = await response.json();
        setNotificationState(data);
      }
    });
  };

  useEffect(() => {
    fetchLoginNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const notificationContextValue: INotificationContext = {
    notificationState,
    setNotificationState,
  };
  const userContextValue: IUserContext = {
    userState: userState,
    setUserState,
  };
  const languageContextValue: ILangContext = {
    langState: langState,
    setLangState,
  };
  const generalContextValue: IGeneralContext = {
    general: generalState,
    setGeneralState,
    ShowNotification: ShowNotification,
  };
  const content = useRoutes(router(userState?.user ? true : false, userState.user?.roles ?? []));

  return (
    <ThemeProvider>
      <LanguageContext.Provider value={languageContextValue}>
        <UserContext.Provider value={userContextValue}>
          <NotificationContext.Provider value={notificationContextValue}>
            <GeneralContext.Provider value={generalContextValue}>
              <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <NotificationContainer />
                {content}
                {notificationType !== NotificationType.none ? (
                  <NotificationBar typeOfNotification={notificationType} message={notificationMessage} closeNotification={() => setNotificationType(NotificationType.none)} />
                ) : null}
                <NotificationContainer />
              </IntlProvider>
            </GeneralContext.Provider>
          </NotificationContext.Provider>
        </UserContext.Provider>
      </LanguageContext.Provider>
    </ThemeProvider>
  );
};
export default App;
