import IntlMessages from '../../../../helpers/IntlMessages';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, InputLabel, MenuItem, Select, TextField, FormControl, Typography, Button, Input, Checkbox, FormHelperText } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import companyService from '../../../../services/companyService';
import { ICompany } from '../../../../interfaces/companyInterface';
import ModalLoader from '../../../../components/common/ModalLoader';
import userService from '../../../../services/userService';
import { IFriendlyUser } from '../../../../interfaces/authInterface';
import { GeneralContext } from '../../../../App';
import { NotificationType } from '../../../../components/common/NotificationBar';
import { useNavigate, useParams } from 'react-router-dom';

const CompanyForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IFriendlyUser[]>([]);
  const [editItem, setEditItem] = useState<ICompany>();
  const appContext = useContext(GeneralContext);
  const { id } = useParams();
  const { control, handleSubmit, watch, reset, setValue } = useForm<ICompany>();
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    userService
      .GetAllUsers()
      .then((responseData) => {
        setUsers(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const fetchDataById = async () => {
    setIsLoading(true);
    companyService
      .GetCompanyById(id)
      .then((responseData) => {
        setEditItem(responseData);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      fetchDataById();
      fetchData();
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editItem && editItem.id) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);

  const fileSizeHandler = async (e: any) => {
    let fileSize = e.target.files[0].size;
    let maxFilesize = 200000;
    if (fileSize > maxFilesize) {
      reset({ companyImage: undefined });
      appContext.ShowNotification(NotificationType.error, 'File is to big, try a smaller one! Max file size is 20000kb');
    } else {
      let logodata = await writeFileToByteArray(e.target.files[0]);
      return setValue('companyImage', logodata);
    }
  };

  const writeFileToByteArray = async (file: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result?.toString() ?? '');
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async (data: ICompany) => {
    setIsLoading(true);
    if (editItem?.id) {
      let updatedItem = await companyService.UpdateCompany(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    } else {
      let submitted = await companyService.AddCompany(data);
      if (!submitted) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.savesuccess" />);
    }
    navigate('/app/admin/company/companies');
  };

  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container marginTop={5} maxWidth="sm" borderRadius={3} justifyContent="center" className="container-fluid color-white">
          <Grid item xs={12} sm={12} marginTop={2}>
            {id ? (
              <Typography>
                <IntlMessages id="company.editcompany" />
              </Typography>
            ) : (
              <Typography>
                <IntlMessages id="company.newcompany" />
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'title'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  size="small"
                  label={<IntlMessages id="form.name" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'country'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="selectedCause">
                    <IntlMessages id="form.country" />
                  </InputLabel>
                  <Select defaultValue="Välj land" size="small" error={!!error} id="Country" label={'Cause'} value={value || ''} onChange={onChange}>
                    <MenuItem value="Sverige">Sverige</MenuItem>
                    <MenuItem value="Norge">Norge</MenuItem>
                    <MenuItem value="Polen">Polen</MenuItem>
                    <MenuItem value="Baltic">Baltic</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: '#FF1943' }}>{error ? <IntlMessages id={error.message} /> : null}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'defaultApproverAndNotificationReceiverIdCollection'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel>
                    <IntlMessages id="form.defaultapprover" />
                  </InputLabel>
                  <Select
                    labelId="answerOptionLabel"
                    label={<IntlMessages id="form.defaultapprover" />}
                    size="small"
                    multiple
                    error={!!error}
                    value={value ? value : []}
                    onChange={onChange}
                    renderValue={(selected) =>
                      selected
                        ? users
                            .filter((x) => selected.includes(x.id))
                            .map((x) => x.title)
                            .join(', ')
                        : ''
                    }
                  >
                    <MenuItem></MenuItem>
                    {users.map((user, index) => (
                      <MenuItem key={index} value={user.id}>
                        {user ? <Checkbox checked={value && value.indexOf(user.id) !== -1 ? true : false} /> : null}
                        {user.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: '#FF1943' }}>{error ? <IntlMessages id={error.message} /> : null}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'companyImage'}
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <>
                  <FormControl fullWidth>
                    <Input id="fileUpload" style={{ display: 'none' }} inputProps={{ accept: 'image/png' }} type="file" onChange={fileSizeHandler}></Input>

                    <Grid container justifyContent="center">
                      <Grid borderRadius={3} border="1px solid black" padding={3}>
                        <img alt="" width={200} src={Boolean(watch('companyImage')) ? watch('companyImage') : require('../../../../assets/DefaultCompany.png')}></img>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Grid marginTop={2}>
                          <label htmlFor="fileUpload">
                            <Button size="small" variant="outlined" component="span">
                              {<IntlMessages id="button.changelogo" />}
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormControl>
                </>
              )}
            />
          </Grid>
          <Grid marginBottom={2} marginTop={2} container justifyContent="space-between">
            <Button onClick={() => navigate(`/app/admin/company/companies`)} variant="outlined">
              {<IntlMessages id="button.abort" />}
            </Button>
            <Button type="submit" variant="contained">
              {<IntlMessages id="button.save" />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CompanyForm;
