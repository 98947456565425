import { Grid, TextField, Button } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import IntlMessages from '../../../helpers/IntlMessages';
import EditIcon from '@mui/icons-material/Edit';
import { IUser } from '../../../interfaces/userInterface';
import { generalHelpers } from '../../../helpers/GenerealHelpers';

type MyPageContactInformationProps = {
  control: Control<IUser, any>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
};

const MyPageContactInformation = ({ control, setIsEditing, isEditing }: MyPageContactInformationProps) => {
  return (
    <>
      <Grid item xs={12} className="padding-bottom">
        {isEditing ? null : (
          <Grid mt={2} container direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
            <Button onClick={() => setIsEditing((prev) => !prev)}>
              <EditIcon />
            </Button>
          </Grid>
        )}
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'title'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.name" />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'socialSecurityNumber'}
              control={control}
              rules={{
                required: 'error-required',
                validate: (value) => generalHelpers.LuhnIsValid(value ?? ''),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9.-]/g, '').replace(/(\..*)\./g, '$1');
                  }}
                  inputProps={{ minLength: 11, maxLength: 11 }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message ? <IntlMessages id={error?.message} /> : null || error?.type === 'validate' ? <IntlMessages id="error.socialsecuritynumber" /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.socialsecuritynumber" />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'email'}
              control={control}
              rules={{
                required: 'error-required',
                pattern: {
                  value:
                    /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$)|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))[0-9]{4}$|^(19|20)[0-9]{2}((0[1-9])|(10|11|12))([7-9]0|[6-9][1-9])[0-9]{4}$/i,
                  message: 'error.notvalidemail',
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <TextField
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? <IntlMessages id={error.message} /> : null}
                    value={value?.toString() || ''}
                    fullWidth
                    disabled
                    size="small"
                    label={<IntlMessages id="form.email" />}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'phoneNumber'}
              control={control}
              rules={{
                required: 'error-required',
                pattern: {
                  value: /^(\+|\d)[0-9]{7,16}$/,
                  message: 'error.notvalidphonenumber',
                },
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9.+]/g, '').replace(/(\..*)\./g, '$1');
                  }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.phonenumber" />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'address'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.address" />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'closestRelative'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.closestrelative" />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} marginTop={1}>
            <Controller
              name={'closestRelativeContactInformation'}
              control={control}
              rules={{ required: 'error-required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? <IntlMessages id={error.message} /> : null}
                  value={value?.toString() || ''}
                  fullWidth
                  disabled={!isEditing}
                  size="small"
                  label={<IntlMessages id="form.closestrelativecontactinformation" />}
                />
              )}
            />
          </Grid>
        </Grid>
        {isEditing ? (
          <>
            <Grid marginBottom={2} marginTop={2} container justifyContent="space-between">
              <Button onClick={() => setIsEditing(false)} variant="outlined" color="primary">
                {<IntlMessages id="button.abort" />}
              </Button>
              <Button type="submit" variant="contained">
                {<IntlMessages id="button.save" />}
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};
export default MyPageContactInformation;
