import React, { useContext, useEffect, useState } from 'react';
import { IAbsence } from '../../../interfaces/absenceInterface';
import IntlMessages from '../../../helpers/IntlMessages';
import { Grid, TextField, Typography, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { GeneralContext, NotificationContext, UserContext } from '../../../App';
import absenceService from '../../../services/absenceService';
import { getDefaultSickAbsence } from '../../../helpers/GetDefault';
import moment from 'moment';
import ModalLoader from '../../../components/common/ModalLoader';
import { NotificationType } from '../../../components/common/NotificationBar';
import GoToNotificationOfHealth from './goToNotificationOfHealth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';

const NewSickAbsence = () => {
  const User = useContext(UserContext);
  const notification = useContext(NotificationContext);
  const [editItem, setAbsence] = useState<IAbsence>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const appContext = useContext(GeneralContext);
  const { id } = useParams();
  let navigate = useNavigate();

  const { pathname } = useLocation();
  const { control, handleSubmit, reset, watch } = useForm<IAbsence>({ defaultValues: getDefaultSickAbsence(User) });

  const fetchAbsenceDataById = async () => {
    setIsLoading(true);
    absenceService
      .GetAbsenceById(id)
      .then(async (responseData) => {
        if (responseData) {
          setAbsence(responseData);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onSubmit = async (data: IAbsence) => {
    setIsLoading(true);
    if (editItem?.id) {
      let updatedItem = await absenceService.UpdateAbsence(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    } else {
      let submitted = await absenceService.AddAbsence(data);
      if (!submitted) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      notification.setNotificationState({ isSick: true, sickDate: data.start, absenceId: submitted.id });
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.yourapplicationhasnowbeenreceived" />);
    }

    pathname.includes('admin') ? navigate(`/app/admin/absence/absences`) : navigate(`/app/absence/myabsence`);
    reset();
  };

  useEffect(() => {
    if (id) {
      fetchAbsenceDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editItem && editItem.selectedCauseId) {
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);
  return (
    <>
      {notification.notificationState.isSick && !id ? (
        <>
          <GoToNotificationOfHealth
            text={
              <>
                <WarningIcon color="error" />
                <IntlMessages id="absence.alreadysick" />
              </>
            }
          />
        </>
      ) : (
        <>
          <ModalLoader isLoading={isLoading} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid marginTop={5} borderRadius={3} container maxWidth="sm" justifyContent="center" alignItems="center" className="container-fluid color-white">
              <Grid item xs={12} sm={12} marginTop={2}>
                {id ? (
                  <Typography>
                    <IntlMessages id="form.editsickabsence" />
                  </Typography>
                ) : (
                  <Typography>
                    <IntlMessages id="absence.headingnewsickabsence" />
                  </Typography>
                )}
              </Grid>
              {id ? (
                <Grid item xs={12} sm={12} marginTop={2}>
                  <Controller
                    name={'title'}
                    control={control}
                    rules={{ required: 'error-required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? <IntlMessages id={error.message} /> : null}
                        value={value?.toString() || ''}
                        fullWidth
                        disabled
                        size="small"
                        label={<IntlMessages id="form.name" />}
                      />
                    )}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} marginTop={2}>
                <Controller
                  name={'start'}
                  control={control}
                  rules={{ required: 'error-required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      size="small"
                      id="date"
                      label={<IntlMessages id="form.date-from" />}
                      error={!!error}
                      helperText={error ? <IntlMessages id={error.message} /> : null}
                      type="date"
                      fullWidth
                      onChange={onChange}
                      value={moment(value).format('YYYY-MM-DD') || ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {editItem ? (
                <Grid item xs={12} sm={12} marginTop={2}>
                  <Controller
                    name={'end'}
                    control={control}
                    rules={{ required: 'error-required', validate: { endDateValid: (v) => moment(v).isSameOrAfter(moment(watch('start')).format('YYYY-MM-DD')) || 'error.enddatabeforestartdate' } }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        size="small"
                        id="date"
                        label={<IntlMessages id="form.date-to" />}
                        error={!!error}
                        helperText={error ? <IntlMessages id={error.message} /> : null}
                        type="date"
                        fullWidth
                        onChange={onChange}
                        value={moment(value).format('YYYY-MM-DD') || ''}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12} marginTop={2}>
                <Controller
                  name={'workedHours'}
                  control={control}
                  rules={{ required: 'error-required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                      }}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? <IntlMessages id={error.message} /> : null}
                      value={value?.toString() || ''}
                      fullWidth
                      size="small"
                      label={<IntlMessages id="form.workedhours" />}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} marginTop={2} marginBottom={2}>
                <Controller
                  name={'comment'}
                  control={control}
                  rules={{ required: 'error-required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? <IntlMessages id={error.message} /> : null}
                      value={value?.toString() || ''}
                      fullWidth
                      multiline
                      maxRows={3}
                      size="small"
                      label={<IntlMessages id="form.comment" />}
                    />
                  )}
                />
              </Grid>
              <Grid marginBottom={2} marginTop={2} container justifyContent="space-between">
                <Button onClick={() => (pathname.includes('admin') ? navigate(`/app/admin/absence/absences`) : navigate(`/app/absence/myabsence`))} variant="outlined">
                  {<IntlMessages id="button.abort" />}
                </Button>
                <Button type="submit" onClick={() => console.log('hej')} variant="contained">
                  {<IntlMessages id="button.save" />}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
};

export default NewSickAbsence;
