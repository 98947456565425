/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Absence

*/

export default {
    /* 01.General */
    'general.copyright': 'Burdy © 2022',
    'general.system-name': 'Olga',
    /* 02.User Login, Logout, Register */
    'user.login-title': 'Logga in',
    'user.login-forgot-password': 'Glömt lösenord',
    'user.login-email': 'Email',
    'user.send-link': 'Skicka återställningslänk',
    'user.reset-password-sent': 'Mail skickat, kolla din inkorg',
    'user.reset-password-sent-admin': 'Mail skickat',
    'user.reset-password-sent-error': 'Mail kunde inte skickas.',
    'user.allusers': 'Alla användare',
    'user.edituser': 'Redigera användare',
    'user.createuser': 'Skapa användare',
    'user.company': 'Företag',
    'user.password': 'Lösenord',
    'user.newpassword': 'Nytt lösenord',
    'user.confirmpassword': 'Bekräfta nytt lösenord',
    'user.changepassword': 'Byt lösenord',
    'user.changeemail': 'Byt mailadress',
    'user.signout-form-header': 'Du är nu utloggad',
    'user.signout-title': 'Du är nu utloggad',
    'user.signout-message': 'Tryck här för att logga in igen.',
  
    /* 03. Absence */
    'absence.dropdown-choose': 'Välj...',
    'absence.dropdown-sick': 'Sjuk',
    'absence.dropdown-vacation': 'Semester',
    'absence.dropdown-parentalLeave': 'Föräldraledighet',
    'absence.dropdown-VAB': 'VAB',
    'absence.dropdown-ATF': 'ATF',
    'absence.dropdown-permission': 'Permission',
    'absence.dropdown-leave': 'Tjänstledighet',
    'absence.dropdown-egenMelding': 'Egenmelding',
    'absence.new-absence': 'Ny frånvaro',
    'absence.headingnewabsence': 'Ny frånvaro',
    'absence.headingnewsickabsence': 'Ny sjukfrånvaro',
    'absence.myabsence': 'Mina ansökningar',
    'absence.myhistoricalabsence': 'Historiska',
    'absence.myongoingabsence': 'Pågående ansökningar',
    'absence.absencemedicalinsuranceneeded': 'Läkarintyg krävs',
    'absence.medicalinsurancecertificatessentin': 'Inskickade läkarintyg',
    'absence.handleabsence': 'Hantera ansökan',
    'absence.waiting': 'Pågående',
    'absence.approved': 'Godkänd',
    'absence.rejected': 'Nekad',
    'absence.statusinformation': 'Statusar',
    'absence.status-1': 'Sjuk',
    'absence.status-2': 'Semester',
    'absence.status-3': 'Tjänstledighet',
    'absence.status-4': 'Föräldraledighet',
    'absence.status-5': 'VAB',
    'absence.status-6': 'ATF',
    'absence.status-7': 'Permission',
    'absence.alreadysick': 'Obs! Du har en pågående sjukperiod. Du måste friskförklara dig innan du kan göra en ny sjukanmälan',
    'absence.gotonotificationofhealth': 'Gå till friskanmälan',
    'absence.notificationofhealth': 'Friskanmälan',
    'absence.dontforgetnotificationofhealth':'Glöm inte att friskanmäla dig när du blivit frisk!',
    'absence.areyousureapprove': 'Är du säker på att du vill godkänna denna frånvaroanmälan?',
    'absence.areyousurereject': 'Är du säker på att du vill neka denna frånvaroanmälan?',
    'absence.cause': 'Orsak',

    /*04. forms */
    'form.employee': 'Anställd',
    'form.workerId': 'Anstnr',
    'form.name': 'Namn',
    'form.date-from': 'Datum från',
    'form.date-to': 'Datum till',
    'form.created': 'Skapad',
    'form.comment': 'Kommentar',
    'form.workedhours': 'Arbetade timmar',
    'form.workedhoursinmonth': 'Arbetade timmar i ',
    'form.logo': 'Logotyp',
    'form.country': 'Land',
    'form.date': 'Datum',
    'form.birthday': 'Födelsedatum',
    'form.socialsecuritynumber': 'Personnummer',
    'form.address': 'Adress',
    'form.postcity': 'Postadress',
    'form.postnumber': 'Postnummer',
    'form.emergencycontact': 'Nödkontakt',
    'form.closestrelative': 'Närmaste anhörig',
    'form.closestrelativecontactinformation': 'Närmast anhörig kontaktinformation',
    'form.sizeshoe': 'Skostorlek',
    'form.sizeupperbody': 'Storlek överkropp',
    'form.sizepants': 'Storlek byxor',
    'form.language': 'Språk',
    'form.driverlicens': 'Körkort',
    'form.accountnumber': 'Kontonummer',
    'form.clearingnumber': 'Clearingnummer',
    'form.iban': 'IBAN',
    'form.swift': 'Swift',
    'form.recieveraccountname': 'Kontoansvarig',
    'form.employmenttype': 'Anställningstyp',
    'form.employmentdate': 'Anställningsdatum',
    'form.employeer': 'Arbetsgivare',
    'form.status': 'Status',
    'form.managername': 'Närmaste chef',
    'form.profession': 'Yrke',
    'form.id06': 'ID06',
    'form.absencestype': 'Orsak',
    'form.phonenumber': 'Telefonnummer',
    'form.workphone': 'Arbetstelefon',
    'form.email': 'Email',
    'form.newemail': 'Ny mailadress',
    'form.verifyemail': 'Verifiera mailadress',
    'form.workemail': 'Arbetsmail',
    'form.role': 'Roll',
    'form.start': 'Start',
    'form.end': 'Slut',
    'form.medicalinsurance': 'Läkarintyg',
    'form.rank': 'Rank',
    'form.rankproject': 'Rank projekt',
    'form.avaragepoints': 'Snitt poäng',
    'form.datestampmonthandyear': 'Månad - år',
    'form.bankdetails': 'Bankuppgifter',
    'form.sizes': 'Storlekar',
    'form.company-information': 'Arbetsgivaruppgifter',
    'form.approver': 'Godkännare',
    'form.decisioncomment': 'Beslutskommentar',
    'form.decisiondate': 'Beslutsdatum',
    'form.filename': 'Filnamn',
    'form.sentin': 'Inskickad',
    'form.newTimereport': 'Ny tidrapport',
    'form.editTimereport': 'Redigera tidrapport',
    'form.edit': 'Redigera',
    'form.editsickabsence': 'Redigera sjukfrånvaro',
    "form.yourapplicationhasnowbeenreceived": 'Din ansökan är nu mottagen.',
    "form.savefailure": 'DET UPPSTOD ETT FEL! OM FELET KVARSTÅR , KONTAKTA IT SUPPORT - SUPPORT@BURDY.SE',
    'form.editabsence': 'Redigera frånvaro',
    'form.projectsupervisor': 'Projekthandledare',
    'form.projectname': 'Projektnamn',
    'form.projectleader': 'Projektledare',
    'form.projectmanager': 'Projektchef',
    'form.projectnumber': 'Projektnummer',
    'form.projects': 'Projekt',
    'form.editproject': 'Redigera projekt',
    'form.createproject': 'Skapa nytt projekt',
    'form.defaultapprover': 'Godkännare',
    'form.approverComment': 'Kommentar från godkännare',
    'form.worker': 'Arbetare',
    'form.mypage': 'Mina uppgifter',
    'form.changeprofilepicture': 'Ändra profilbild',
    'form.requiredfield': 'Du måste fylla i det här fältet',
    'form.userproject': 'Projektplanering',
    'form.edituserproject': 'Redigera projektplanering',
    'form.savesuccess': 'Sparning lyckades',
    'form.updatesuccess': 'Uppdatering lyckades',
    'form.medicalcertificatesent': 'Läkarintyg är nu inskickat.',
    'form.year':'År',
    'form.deletesuccess': 'Radering lyckades',

    /* 05. Button */
    'button.save': 'Spara',
    'button.edit': 'Redigera',
    'button.undoedit': 'Avbryt',
    'button.approve': 'Godkänn',
    'button.reject': 'Neka',
    'button.handle': 'Hantera',
    'button.back': 'Tillbaka',
    'button.delete': 'Radera',
    'button.addnew': 'Skapa ny',
    'button.newlogo': 'Ny logotyp',
    'button.changelogo': 'Ändra logotyp',
    'button.goback': 'Gå tillbaka',
    'button.abort': 'Avbryt',
    'button.fileupload': 'Ladda upp fil',
    'button.reset-password-button': 'Skicka återställningsmail',
    'button.resetallpasswords': 'Skicka ut välkomstmail till alla som ej har loggat in',
  
   /* 06. topbar-nav */
  'topbar-nav.absence-start': 'Frånvaro',
  'topbar-nav.project-start': 'Projekt',
  'topbar-nav.workerplanner-start': 'Planering',
  'topbar-nav.timereporting-start': 'Tidrapportering',
  'topbar-nav.administration-start': 'Administration',
  'topbar-nav.newabsence': 'Ny frånvaro',
  'topbar-nav.newsickleave': 'Ny sjukanmälan',
  'topbar-nav.myabsence': 'Mina ansökningar',
  'topbar-nav.createcompany': 'Skapa företag',
  'topbar-nav.allcompanies': 'Alla företag',
  'topbar-nav.allusers': 'Alla användare',
  'topbar-nav.absencetoreview': 'Frånvaro ansökningar',
  'topbar-nav.historicabsence': 'Historiska frånvaro ansökningar',
  'topbar-nav.start': 'Start',
  'topbar-nav.workerplannerdata': 'Planering ',
  'topbar-nav.workerplannerdata-overview': 'Översikt',
  'topbar-nav.project': 'Projekt',
  'topbar-nav.registeruser': 'Registrera användare',
  'topbar-nav.timereport-overview': 'Översikt',
  'topbar-nav.userproject':'Projektplanering',
  'topbar-nav.myprojects':'Mina projekt',
  'topbar-nav.profile':'Min profil',
  'topbar-nav.employeerinformation':'Arbetsgivaruppgifter',
  'topbar-nav.account':'Mitt konto',
  'topbar-nav.evaluation':'Utvärdering',
  
    /* 07. Company */
    'company.newcompany': 'Nytt bolag',
    'company.editcompany': 'Redigera bolag',
    'company.allcompanies': 'Alla bolag',
  
    /* 08. Validation */
    'validation.enter-start-date': 'Du har ej fyllt i något start datum',
    'validation.enter-end': 'Du har ej fyllt i något slut datum',
  
    /* 09. ProfessionDropdown */
    'profession.dropdown-concrete': 'Betongare',
    'profession.dropdown-plaster': 'Gipsare',
    'profession.dropdown-carpenter': 'Snickare',
    'profession.dropdown-framecompletion': 'Stomkomplettering',
    'profession.dropdown-constructionservice': 'Byggservice',
    'profession.dropdown-rivare': 'Rivare',
    'profession.dropdown-supervisor': 'Arbetsledare',
    'profession.dropdown-leadinginstaller': 'Ledande montör',
    'profession.dropdown-workmanager': 'Arbetschef',
    'profession.dropdown-offical': 'Tjänsteman',
    'profession.dropdown-cranedriver': 'Kranförare',
    'profession.dropdown-trainee': 'Praktikant',
    'profession.dropdown-painter': 'Målare',
    'profession.dropdown-welder': 'Svetsare',
    'profession.dropdown-suspension': 'Utsättare',
    'profession.dropdown-groundwork': 'Markarbete',
    'profession.dropdown-truckdriver': 'Lastbilsförare',
    'profession.dropdown-electrician': 'Elektriker',
    'profession.dropdown-pumpdriver': 'Pumpförare',
    'profession.dropdown-excavatoroperator': 'Grävmaskinsförare',
    'profession.dropdown-professionmissing': 'Yrke saknas',
  
    /* 10. Driverlicens dropdown */
    'driverlicens.dropdown-am': 'AM - moped klass I',
    'driverlicens.dropdown-a1': 'A1 – lätt motorcykel',
    'driverlicens.dropdown-a2': 'A2 - mellanstor motorcykel',
    'driverlicens.dropdown-a': 'A – tung motorcykel',
    'driverlicens.dropdown-b96': 'B96 - personbil och tyngre släp än vanligt B-körkort',
    'driverlicens.dropdown-b': 'B – personbil och lätt lastbil',
    'driverlicens.dropdown-be': 'BE – personbil med tungt släp',
    'driverlicens.dropdown-c1-c1e': 'C1 och C1E - medeltung lastbil',
    'driverlicens.dropdown-c-ce': 'C och CE – tung lastbil',
    'driverlicens.dropdown-d1-d1e': 'D1 och D1E - mellanstor buss',
    'driverlicens.dropdown-d-de': 'D och DE – buss',
    'driverlicens.dropdown-t': 'T - Traktor',
    'driverlicens.dropdown-tlp1': 'TLP 1 - Truck',
    'driverlicens.dropdown-none': 'Ingen',
  
    /* 11. Language dropdown */
    'language.dropdown-en': 'Engelska',
    'language.dropdown-ee': 'Estiska',
    'language.dropdown-fi': 'Finska',
    'language.dropdown-lv': 'Lettiska',
    'language.dropdown-lt': 'Litauiska',
    'language.dropdown-pl': 'Polska',
    'language.dropdown-de': 'Tyska',
    'language.dropdown-ru': 'Ryska',
    'language.dropdown-no': 'Norska',
    'language.dropdown-se': 'Svenska',
    'language.dropdown-ua': 'Ukrainska',
  
    'heading.absencetoreview': 'Frånvaro ansökningar',
    'heading.historicabsencetoreview': 'Historiska frånvaro ansökningar',
    'heading.user-information': 'Kontaktuppgifter',
    'heading.company-information': 'Arbetsgivaruppgifter',
    'heading.edit-user': 'Redigera användare',
    'heading.bankdetails': 'Bankuppgifter',
    // "heading.company-information": 'Arbetsgivaruppgifter',
  
    'Size.S': 'S',
    'Size.M': 'M',
    'Size.L': 'L',
    'Size.XL': 'XL',
    'Size.XXL': 'XXL',
  
    /*12. MISC */
    'info.delete-confirm': 'Är du säker på att du vill radera detta objekt?',


    /*13 ERRORS */
    'error-required': 'Du måste fylla i detta fält.',
    'error.notvalidemail': 'Det är inte en giltig mailaddress.',
    'error.socialsecuritynumber': 'Personnummer ska skrivas ÅÅMMDD-XXXX.',
    'error.selectcause':'Fyll i anledning till ledighet.',
    'error.fourteendaysahead': 'Datumet måste vara minst 14 dagar fram i tid.',
    'error.enddatabeforestartdate': 'Slutdatum kan inte vara innan startdatum.',
    'error-file': 'Du måste ladda upp en fil.',
    'error.incorrectpassword': 'Minst 8 tecken, minst en stor bokstav, en liten bokstav, ett nummer och ett specialtecken.',
    'error.requiredhours':'Du måste fylla i antal timmar.',
    'error.chooseproject': 'Välj ett projekt att rapportera tiden på.',
    'error.passworddoesnotmatch': 'Lösenorden matchar inte varandra.',
    'error.warning': 'Varning!',
    'error.notvalidphonenumber': 'Det är inte ett giltigt telefonnummer.',
    'error.notmatchingemails': 'Mailaddresserna stämmer inte överens.',


    /*14 MONTHS */
    'months.january': 'Januari',
    'months.february': 'Februari',
    'months.mars': 'Mars',
    'months.april': 'April',
    'months.may': 'Maj',
    'months.june': 'June',
    'months.july': 'Juli',
    'months.august': 'Augusti',
    'months.september': 'September',
    'months.october': 'Oktober',
    'months.november': 'November',
    'months.december': 'December',
    'months.month': 'Månad',
  };
  