import { IAbsence } from '../interfaces/absenceInterface';
import { ITimereport } from '../interfaces/timereportInterface';
import { IUserContext } from '../context/userContext';
import dayjs from 'dayjs';

export const getDefaultAbsence = (User: IUserContext): IAbsence => {
  return {
    title: User.userState?.user?.title ?? '',
    comment: '',
    companyId: User.userState?.user?.companyId ?? '',
    projectNumber: 0,
    userId: User.userState?.user?.id ?? '',
    workedHours: 0,
    workerID: User.userState?.user?.workerID ?? 0,
    created: new Date(),
    start: new Date(),
    status: 0 ?? '',
    approverComment: '',
  };
};

export const getDefaultSickAbsence = (User: IUserContext): IAbsence => {
  return {
    title: User.userState?.user?.title ?? '',
    comment: '',
    companyId: User.userState?.user?.companyId ?? '',
    projectNumber: 0,
    userId: User.userState?.user?.id ?? '',
    workedHours: 0,
    workerID: User.userState?.user?.workerID ?? 0,
    selectedCauseId: 1,
    created: new Date(),
    status: 0,
    start: new Date(),
    approverComment: '',
  };
};
export const getDefaultTimereport = (User: IUserContext, date: string): ITimereport => {
  return {
    companyId: User.userState?.user?.companyId ?? '',
    timeCode: 0,
    projectId: '',
    project: undefined,
    workerName: User?.userState?.user?.title ?? '',
    workerID: User?.userState?.user?.workerID ?? 0,
    dateStamp: dayjs(date).toDate(),
    comment: '',
    userId: User?.userState?.user?.id ?? '',
  };
};
