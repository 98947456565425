import moment from 'moment';
import { IAbsence } from '../../../interfaces/absenceInterface';

var Holidays = require('date-holidays');
var hd = new Holidays();
hd.init('SE');

export const GetDropdownOptionsByLanguage = (companyId: string) => {
  switch (companyId?.toLowerCase()) {
    case 'C0CF5D0B-0569-43DC-B55F-08D88BCD5DED'.toLowerCase():
      return AbsenceDropdownNorway;
    default:
      return AbsenceDropdownSweden;
  }
};

const AbsenceDropdownSweden = [
  { key: 2, text: 'absence.dropdown-vacation', disabled: true },
  { key: 3, text: 'absence.dropdown-leave' },
  { key: 4, text: 'absence.dropdown-parentalLeave' },
  { key: 5, text: 'absence.dropdown-VAB' },
  { key: 6, text: 'absence.dropdown-ATF' },
  { key: 7, text: 'absence.dropdown-permission' },
];
const AbsenceDropdownNorway = [
  { key: 2, text: 'absence.dropdown-vacation' },
  { key: 3, text: 'absence.dropdown-leave' },
  { key: 4, text: 'absence.dropdown-parentalLeave' },
  { key: 5, text: 'absence.dropdown-VAB' },
  { key: 6, text: 'absence.dropdown-ATF' },
  { key: 7, text: 'absence.dropdown-permission' },
  { key: 8, text: 'absence.dropdown-egenMelding' },
];

export const GetDropdownOptionsByLanguage2 = (companyId: string) => {
  switch (companyId.toLowerCase()) {
    case 'C0CF5D0B-0569-43DC-B55F-08D88BCD5DED'.toLowerCase():
      return AbsenceDropdownNorway2;
    default:
      return AbsenceDropdownSweden2;
  }
};

const AbsenceDropdownSweden2 = [
  { key: 1, text: 'absence.dropdown-sick' },
  { key: 2, text: 'absence.dropdown-vacation' },
  { key: 3, text: 'absence.dropdown-leave' },
  { key: 4, text: 'absence.dropdown-parentalLeave' },
  { key: 5, text: 'absence.dropdown-VAB' },
  { key: 6, text: 'absence.dropdown-ATF' },
  { key: 7, text: 'absence.dropdown-permission' },
];
const AbsenceDropdownNorway2 = [
  { key: 1, text: 'absence.dropdown-sick' },
  { key: 2, text: 'absence.dropdown-vacation' },
  { key: 3, text: 'absence.dropdown-leave' },
  { key: 4, text: 'absence.dropdown-parentalLeave' },
  { key: 5, text: 'absence.dropdown-VAB' },
  { key: 6, text: 'absence.dropdown-ATF' },
  { key: 7, text: 'absence.dropdown-permission' },
  { key: 8, text: 'absence.dropdown-egenMelding' },
];

export const validateEndDateStandard = (value: any, country: string, startDate: any) => {
  if (moment(value) < moment(startDate)) {
    return 'Slutdatum kan inte vara innan startdatum ';
  }
  return '';
};
export const validateStartDateStandard = (value: any, country: string) => {
  if (country === 'norway') {
    if (moment(value).startOf('day') < moment().add(4, 'weeks').startOf('day')) {
      return 'Datumet måste vara minst 4 veckor framåt i tid.';
    }
  } else {
    if (moment(value).startOf('day') < moment().add(14, 'days').startOf('day')) {
      return 'Datumet måste vara minst 14 dagar fram i tid.';
    }
  }
  return '';
};
export const ValidateVacationForm = (absence: any, country: string, paidVacation: number) => {
  // if (absence.companyId === "polen,baltic") {
  //     absence.status = 2;
  //     return {err: '', obj: absence};
  // }
  const dates = GetBusinessDays(absence.start, absence.end);
  if (dates > paidVacation) {
    return { err: `Du har inte tillräckligt med semesterdagar kvar. Antal dagar sökta: ${dates}.`, obj: absence };
  }

  //1 juli - 11 augusti
  let year = moment() > moment(`${moment().year()}-08-11`) ? moment().year() + 1 : moment().year();
  let byggStart = moment(`${year}-07-01`);
  let byggEnd = moment(`${year}-08-11`);
  let momentStart = moment(absence.start);
  let momentEnd = moment(absence.end);

  if (momentStart.isBetween(byggStart, byggEnd) && momentEnd.isBetween(byggStart, byggEnd)) {
    absence.status = 2;
    return { err: '', obj: absence };
  }
  return { err: '', obj: absence };
};
export const ValidateParentalLeaveForm = (absence: IAbsence, country: string) => {
  // if(country === 'norway'){
  //     const amountDays = GetDaysAmount(absence.start, absence.end);
  //     if(amountDays >= 14 && amountDays < 84 && moment(absence.start).startOf('day') < moment().add(1,'week').startOf('day') ) {
  //         return {err: 'Måste söka 1 vecka i förväg för en ledighet på minst 2 veckor', obj: absence };
  //     }
  //     else if(amountDays >= 84 && amountDays < 365 && moment(absence.start).startOf('day') < moment().add(4,'weeks').startOf('day') ){
  //         return {err: 'Måste söka minst 4 veckor i förväg för en ledighet på minst 12 veckor', obj: absence };
  //     }
  //     else if(amountDays >= 365 && moment(absence.start).startOf('day') < moment().add(12,'weeks').startOf('day')){
  //         return {err: 'Måste söka minst 12 veckor i förväg för en ledighet på minst 1 år.', obj: absence };
  //     }
  //     absence.status = 1;
  //     return { err: '', obj: absence };
  // }
  if (moment(absence.start).startOf('day') > moment().add(2, 'months').startOf('day')) {
    absence.status = 2;
    return { err: '', obj: absence };
  }
  absence.status = 0;
  return { err: '', obj: absence };
};

// const GetDaysAmount = (startDate?: Date, endDate?: Date) => {
//   let start = moment(startDate);
//   let end = moment(endDate);
//   let amount = 0;
//   while (start <= end) {
//     amount++;
//     start.add(1, 'day');
//   }
//   return amount;
// };

const GetBusinessDays = (start: Date, end: Date) => {
  let holidays1: any[] = hd.getHolidays(moment(start).year());
  let holidays2: any[] = hd.getHolidays(moment(start).year() + 1);
  let holidays: any[] = holidays1.concat(holidays2);

  holidays = holidays.filter((p: any) => p.type !== 'observance').map((o: any) => moment(o.date).toISOString());

  let startDate = moment(start);
  let endDate = moment(end);
  let dates: Date[] = [];

  //add all dates in range
  while (startDate <= endDate) {
    if (startDate.day() !== 0 && startDate.day() !== 6) {
      if (!holidays.includes(startDate.toISOString())) {
        dates.push(new Date(startDate.toISOString()));
      }
    }
    startDate.add(1, 'day');
  }
  return dates.length;
};
export const validateStartDateSick = (date: any) => {
  if (!date) {
    return 'choose a date';
  }
  return '';
};
export const validateSickAbsence = (absence: IAbsence) => {};
export const validateSickAbsenceNorway = (absence: IAbsence) => {};
