import { Button, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IntlMessages from '../../../helpers/IntlMessages';
type GotoNotoProps = {
  text: any;
};
const GoToNotificationOfHealth = ({ text }: GotoNotoProps) => {
  let navigate = useNavigate();
  return (
    <Grid mt={2} container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
      <Grid item sm={12} md={8} className="padding-bottom">
        <Card>
          <CardHeader title={text}></CardHeader>
          <Divider />
          <CardContent>
            <Button variant="contained" onClick={() => navigate(`/app/absence/notificationofhealth`)}>
              <IntlMessages id="absence.gotonotificationofhealth" />
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default GoToNotificationOfHealth;
