import { Box, IconButton, Avatar, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import React, { useState, useContext } from 'react';

import { LanguageContext } from '../App';
import { setCurrentLanguage } from '../helpers/Utils';
import { localeOptions } from '../constants/defaultValues';

const Languages = (props: any) => {
  // eslint-disable-next-line
  const [state, setState] = useState({ visible: false });
  const langCtx = useContext(LanguageContext);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const toggleUserActions = (ev: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(ev.currentTarget);
    setState((prev) => ({
      ...prev,
      visible: !prev.visible,
    }));
  };
  const setLanguage = (id: string) => {
    langCtx.setLangState((prev) => ({ ...prev, lang: id }));
    setCurrentLanguage(id);
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open lanugage picker">
        <IconButton
          onClick={(ev) => {
            toggleUserActions(ev);
          }}
          sx={{ p: 0 }}
        >
          <Avatar alt="Remy Sharp" src={require(`../constants/flags/${langCtx.langState.lang}.png`)} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        {localeOptions.map((l) => (
          <MenuItem key={l.id} onClick={() => setLanguage(l.id)}>
            <Typography textAlign="center">
              <img alt="Flag icon" src={require(`../constants/flags/${l.id}.png`)} style={{ maxHeight: '30px' }} /> {l.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default Languages;
