class generalHelper {
  public LuhnIsValid = (input: string) => {
    // Clean input
    input = input.replace('-', '');
    // Declare variables
    let sum = 0,
      numdigits = input.length,
      parity = numdigits % 2,
      i,
      digit;
    // Check luhn algorithm
    for (i = 0; i < numdigits; i++) {
      digit = parseInt(input.charAt(i), 10);
      if (i % 2 === parity) {
        digit *= 2;
      }
      if (digit > 9) {
        digit -= 9;
      }
      sum += digit;
    }
    return sum % 10 === 0;
  };
}
export const generalHelpers = new generalHelper();
