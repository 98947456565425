

export interface ILanguageState {
    lang: string;
  }
  export interface ILangContext {
    langState: ILanguageState;
    setLangState: React.Dispatch<React.SetStateAction<ILanguageState>>;
  }
  
  export const initialLangContext: ILangContext = {
    langState: {lang: 'sv'},
    setLangState: () => {},
  }