import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IntlMessages from '../../../helpers/IntlMessages';
import Paper from '@mui/material/Paper';
import { styled, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { UserContext } from '../../../App';
import dayjs from 'dayjs';
import ModalLoader from '../../../components/common/ModalLoader';
import workerPlannerDataService from '../../../services/workerPlannerDataService';
import { IWorkerPlannerData } from '../../../interfaces/workerPlannerDataInterface';

const MyProjects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [AllMyProjects, setAllMyProjects] = useState<IWorkerPlannerData[]>([]);

  const user = useContext(UserContext);

  const fetchData = async () => {
    setIsLoading(true);
    workerPlannerDataService
      .GetAllProjectsByWorkerId(user.userState?.user?.workerID ? user.userState?.user?.workerID : 0)
      .then(async (wpdProjects) => {
        if (wpdProjects) {
          setAllMyProjects(wpdProjects);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#5390d9',
      color: theme.palette.common.white,
      fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: theme.palette.common.white,
    },
  }));

  let navigate = useNavigate();
  const addNewButton = () => {
    navigate('/app/admin/project/projectform/');
  };
  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <Typography variant="h6">
        <IntlMessages id="form.projects" />
      </Typography>
      {user.userState?.user?.roles?.includes('Admin') ? (
        <Button onClick={addNewButton}>
          <AddIcon />
          <IntlMessages id="button.addnew" />
        </Button>
      ) : null}

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <IntlMessages id="form.projectnumber" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.start" />
              </StyledTableCell>
              <StyledTableCell align="left">
                <IntlMessages id="form.end" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AllMyProjects
              ? AllMyProjects?.map((item, i) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{item.projectNumber}</TableCell>
                    <TableCell align="left">{dayjs(item.start).format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="left">{dayjs(item.end).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyProjects;
