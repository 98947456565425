import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router-dom';

import React from 'react';
import SuspenseLoader from './components/common/suspenseLoader';

import DefaultLayout from './layouts/DefaultLayout';
import RootLayout from './layouts/RootLayout';
import AllCompanies from './views/app/admin/company/companies';
import CompanyForm from './views/app/admin/company/companyForm';
import AbsenceForm from './views/app/admin/absence/absenceForm';
import AbsenceApprove from './views/app/admin/absence/absenceApprove';
import ShowAllHistoricAbsences from './views/app/admin/absence/historicAbsences';
import UserProjects from './views/app/project/userProjects';
import UserProjectForm from './views/app/project/userProjectForm';
import MyPage from './views/app/user/myPage';
import MyAccount from './views/app/user/myAccount';
import MyPageEmployeerInformation from './views/app/user/myPageEmployeerInformation';
import MyProjects from './views/app/project/myProjects';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Login
const Root = Loader(lazy(() => import('./views/root/index')));
const Invitation = Loader(lazy(() => import('./views/root/invitation')));
const ForgotPassword = Loader(lazy(() => import('./views/root/forgotpassword')));
const ResetPassword = Loader(lazy(() => import('./views/root/resetPassword')));
const SignOut = Loader(lazy(() => import('./views/root/signout')));
// Dashboards

// Applications
const Start = Loader(lazy(() => import('./views//app')));

const TimeReportForm = Loader(lazy(() => import('./views/app/timereporting/forms/TimereportForm')));
//Absence
const Absence = Loader(lazy(() => import('./views/app/absence/absence')));
const MyAbsence = Loader(lazy(() => import('./views/app/absence/myAbsence')));
const NewAbsence = Loader(lazy(() => import('./views/app/absence/newAbsence')));
const NewSickAbsence = Loader(lazy(() => import('./views/app/absence/newSickAbsence')));
const MedicalInsurance = Loader(lazy(() => import('./views/app/absence/medicalInsurance')));
const NotificationOfHealth = Loader(lazy(() => import('./views/app/absence/notificationOfHealth')));

const WorkerPlannerData = Loader(lazy(() => import('./views/app/wpd')));

const ProjectOverView = Loader(lazy(() => import('./views/app/project/projectOverview')));
const ProjectForm = Loader(lazy(() => import('./views/app/project/projectForm')));

const TimeReporting = Loader(lazy(() => import('./views/app/timereporting')));

//MyPage
const MyEvaluation = Loader(lazy(() => import('./views/app/user/evaluations/MyEvaluation')));

//Admin stuff
const AllUsers = Loader(lazy(() => import('./views/app/admin/user/users')));
const UserForm = Loader(lazy(() => import('./views/app/admin/user/userForm')));
const ShowAllAbsences = Loader(lazy(() => import('./views/app/admin/absence/absences')));

const routes = (isLoggedIn: boolean, userRoles: string[]): RouteObject[] => [
  {
    path: '',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <Root />,
      },
      {
        path: 'forgotpassword',
        element: <ForgotPassword />,
      },
      {
        path: 'invitation',
        element: <Invitation />,
      },
      {
        path: 'signout',
        element: <SignOut />,
      },
      {
        path: 'resetpassword',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: 'app',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <Start />,
      },
    ],
  },
  {
    path: 'app/absence',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <Absence />,
      },
      {
        path: 'newabsence',
        element: <NewAbsence />,
        children: [
          {
            path: ':id',
            element: <NewAbsence />,
          },
        ],
      },
      {
        path: 'newsickabsence',
        element: <NewSickAbsence />,
        children: [
          {
            path: ':id',
            element: <NewSickAbsence />,
          },
        ],
      },
      {
        path: 'myabsence',
        element: <MyAbsence />,
      },
      {
        path: 'medicalinsurance/:id',
        element: <MedicalInsurance />,
      },
      {
        path: 'notificationofhealth',
        element: <NotificationOfHealth />,
      },
    ],
  },
  {
    path: 'app/timereporting',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <TimeReporting />,
      },
      {
        path: 'form',
        element: <TimeReportForm />,
        children: [
          {
            path: ':id',
            element: <TimeReportForm />,
          },
        ],
      },
    ],
  },
  {
    path: 'app/project',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <ProjectOverView />,
      },
      {
        path: 'myProjects',
        element: <MyProjects />,
      },
      {
        path: 'form',
        element: <ProjectForm />,
        children: [
          {
            path: ':id',
            element: <ProjectForm />,
          },
        ],
      },
    ],
  },
  {
    path: 'app/mypage/:id',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <MyPage />,
      },
    ],
  },
  {
    path: 'app/mypageemployeerinformation/:id',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <MyPageEmployeerInformation />,
      },
    ],
  },
  {
    path: 'app/myaccount/:id',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <MyAccount />,
      },
    ],
  },
  {
    path: 'app/userproject',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <UserProjects />,
      },
      {
        path: 'form',
        element: <UserProjectForm />,
        children: [
          {
            path: ':id',
            element: <UserProjectForm />,
          },
        ],
      },
    ],
  },

  {
    path: 'app/wpd',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: <WorkerPlannerData />,
      },
    ],
  },
  {
    path: 'app/myaccount',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      {
        path: 'myevaluation',
        element: <MyEvaluation />,
      },
    ],
  },
  {
    path: 'app/admin',
    element: !isLoggedIn || !userRoles.includes('Admin') ? <Navigate to="/" /> : <DefaultLayout />,
    children: [
      {
        path: 'user/allUsers',
        element: <AllUsers />,
      },
      {
        path: 'user/userform',
        element: <UserForm />,
        children: [
          {
            path: ':id',
            element: <UserForm />,
          },
        ],
      },
      {
        path: 'absence/absences',
        element: <ShowAllAbsences />,
      },
      {
        path: 'absence/historicAbsences',
        element: <ShowAllHistoricAbsences />,
      },
      {
        path: 'absence/absenceForm/:id',
        element: <AbsenceForm />,
      },
      {
        path: 'absence/absenceApprove/:id',
        element: <AbsenceApprove />,
      },
      {
        path: 'company/companies',
        element: <AllCompanies />,
      },
      {
        path: 'company/companyForm',
        element: <CompanyForm />,
        children: [
          {
            path: ':id',
            element: <CompanyForm />,
          },
        ],
      },
      {
        path: 'project/projectOverview',
        element: <ProjectOverView />,
      },
      {
        path: 'project/projectForm',
        element: <ProjectForm />,
        children: [
          {
            path: ':id',
            element: <ProjectForm />,
          },
        ],
      },
      {
        path: 'project/userProjects',
        element: <UserProjects />,
      },
      {
        path: 'project/userProjectForm',
        element: <UserProjectForm />,
        children: [
          {
            path: ':id',
            element: <UserProjectForm />,
          },
        ],
      },
    ],
  },
];

export default routes;
