import { Badge, Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { NavLink as RouteNavLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IntlMessages from '../helpers/IntlMessages';
import { NotificationContext } from '../App';

type MuNavDropdownProps = {
  page: any;
  children: any;
};

export const MuiNavDropdown = ({ page }: MuNavDropdownProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const notfi = useContext(NotificationContext);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      {notfi.notificationState.isSick && page.title === 'topbar-nav.absence-start' ? (
        <Button key={page.title} onClick={handleOpenUserMenu} sx={{ color: 'white', display: 'flex' }} endIcon={<KeyboardArrowDownIcon />}>
          <Badge
            style={{ paddingRight: 10 }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            color="error"
            overlap="circular"
            badgeContent=" "
            variant="dot"
          >
            <IntlMessages id={page.title} />
          </Badge>
        </Button>
      ) : (
        <Button key={page.title} onClick={handleOpenUserMenu} sx={{ color: 'white', display: 'flex' }} endIcon={<KeyboardArrowDownIcon />}>
          <IntlMessages id={page.title} />
        </Button>
      )}
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {page.children.map((child: any) => (
          <MenuItem component={RouteNavLink} to={child.to} key={child.name} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">
              <IntlMessages id={child.name} />
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
