import React from 'react';
import { IAbsence } from '../../../interfaces/absenceInterface';
import IntlMessages from '../../../helpers/IntlMessages';
import { Grid, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment';

type AbsenceAtfProps = {
  control: Control<IAbsence, any>;
  selectedCauseId: number | undefined;
};
const AbsenceAtf = ({ control, selectedCauseId }: AbsenceAtfProps) => {
  return (
    <>
      <Grid item xs={12} sm={12} marginTop={2}>
        <Controller
          name={'start'}
          control={control}
          rules={{ required: 'error-required' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              size="small"
              id="date"
              label={<IntlMessages id="form.date-from" />}
              error={!!error}
              helperText={error ? <IntlMessages id={error.message} /> : null}
              type="date"
              fullWidth
              onChange={onChange}
              value={moment(value).format('YYYY-MM-DD') || ''}
              disabled={selectedCauseId === undefined ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} marginTop={2}>
        <Controller
          name={'workedHours'}
          control={control}
          rules={{ required: 'error-required' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              error={!!error}
              helperText={error ? <IntlMessages id={error.message} /> : null}
              value={value?.toString() || ''}
              fullWidth
              size="small"
              disabled={selectedCauseId === undefined ? true : false}
              label={<IntlMessages id="form.workedhours" />}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} marginTop={2} marginBottom={2}>
        <Controller
          name={'comment'}
          control={control}
          rules={{ required: 'error-required' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              error={!!error}
              helperText={error ? <IntlMessages id={error.message} /> : null}
              value={value?.toString() || ''}
              fullWidth
              size="small"
              disabled={selectedCauseId === undefined ? true : false}
              label={<IntlMessages id="form.comment" />}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default AbsenceAtf;
