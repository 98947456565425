import React, { useContext, useEffect, useState } from 'react';
import { Tooltip, Avatar, Box, Toolbar, IconButton, Menu, MenuItem, Typography, AppBar } from '@mui/material';
import Languages from './Languages';
import NavItems from '../constants/topbar-nav-items';
import IntlMessages from '../helpers/IntlMessages';
import { NavLink as RouteNavLink } from 'react-router-dom';
import { MuiNavDropdown } from './MuiNavDropdown';
import { UserContext } from '../App';
import { signOutCurrentUser } from '../helpers/Utils';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import userService from '../services/userService';
import { IUser } from '../interfaces/userInterface';

export const TopMenuLayout = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [User, setUser] = useState<IUser>();

  const user = useContext(UserContext);
  const settings = [
    { name: <IntlMessages id="topbar-nav.profile" />, id: 1, to: `/app/mypage/${user.userState?.user?.id}` },
    { name: <IntlMessages id="topbar-nav.employeerinformation" />, id: 2, to: `/app/mypageemployeerinformation/${user.userState?.user?.id}` },
    { name: <IntlMessages id="topbar-nav.account" />, id: 3, to: `/app/myaccount/${user.userState?.user?.id}` },
    { name: <IntlMessages id="topbar-nav.evaluation" />, id: 4, to: '/app/myaccount/myevaluation' },
  ];
  const fetchDataById = async () => {
    userService
      .GetUserById(user.userState?.user?.id)
      .then((responseData) => {
        setUser(responseData);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchDataById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const onSignOut = () => {
    signOutCurrentUser();
    user.setUserState((prev) => ({ ...prev, user: undefined }));
    handleCloseUserMenu();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <HomeIcon sx={{ display: { xs: 'none', md: 'flex' } }} />
          <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit" style={{ marginRight: 'auto' }}>
            <MenuIcon sx={{ display: { xs: 'flex', md: 'none' } }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {NavItems().filter(x => user.userState?.user?.roles.some(p => x.roles?.includes(p))).map((page) => {
              return (
                <div key={page.title}>
                  <Typography fontWeight={600} textAlign="start">
                    <IntlMessages id={page.title} />
                  </Typography>
                  {page.children.filter(x => user.userState?.user?.roles.some(p => x.roles?.includes(p))).map((l, i) => (
                    <MenuItem component={RouteNavLink} to={l.to} key={i} onClick={handleCloseNavMenu}>
                      <IntlMessages id={l.name} />
                    </MenuItem>
                  ))}
                </div>
              );
            })}
          </Menu>
          <Typography
            variant="h6"
            noWrap
            component={RouteNavLink}
            to={'/app'}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'Inter',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}
          >
            <IntlMessages id={'general.system-name'} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {NavItems().filter(x => user.userState?.user?.roles.some(p => x.roles?.includes(p))).map((page: any, i: number) => (
              <MuiNavDropdown key={i} page={page} children={page.children} />
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                <Avatar alt="User profile avatar" src={User?.profilePicture ?? require('../constants/default-employee.jpg')} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem component={RouteNavLink} to={setting.to} key={setting.id} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem component={RouteNavLink} to={'/signout'} key={'logout-btn'} onClick={onSignOut}>
                <Typography sx={{ color: 'red' }} textAlign="center">
                  {'Signout'}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Languages isStart={false} />
        </Toolbar>
      </AppBar>
    </Box>
  );
};
