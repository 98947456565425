/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Absence

*/

export default {
  /* 01.General */
  'general.copyright': 'Burdy © 2022',
  'general.system-name': 'Olga',
  /* 02.User Login, Logout, Register */
  'user.login-title': 'Zaloguj się',

  /* 03. Absence */
  'absence.dropdown-choose': 'Välj...',
  'absence.dropdown-sick': 'Sjuk',
  'absence.dropdown-vacation': 'Semester',
  'absence.dropdown-parentalLeave': 'Föräldraledighet',
  'absence.dropdown-VAB': 'VAB',
  'absence.dropdown-ATF': 'ATF',
  'absence.dropdown-permission': 'Permission',
  'absence.dropdown-leave': 'Tjänstledighet',
  'absence.new-absence': 'Ny frånvaro',
  'absence.headingnewabsence': 'Ny frånvaro',
  'absence.headingnewsickabsence': 'Ny sjukfrånvaro',
  'absence.myabsence': 'Mina ansökningar',

  /*04. forms */
  'form.workerId': 'Anställningsnummer',
  'form.name': 'Namn',
  'form.date-from': 'Datum från',
  'form.date-to': 'Datum till',
  'form.created': 'Skapad',
  'form.comment': 'Kommentar',
  'form.workedhours': 'Arbetade timmar',
  'form.logo': 'Logga',
  'form.country': 'Land',
  'form.birthday': 'Födelsedatum',
  'form.socialsecuritynumber': 'Personnummer',
  'form.address': 'Adress',
  'form.postcity': 'Postadress',
  'form.postnumber': 'Postnummer',
  'form.closestrelative': 'Närmaste anhörig',
  'form.closestrelativecontactinformation': 'Närmast anhörig kontaktinformation',
  'form.sizeshoe': 'Skostorlek',
  'form.sizeupperbody': 'Storlek överkropp',
  'form.sizepants': 'Storlek byxor',
  'form.language': 'Språk',
  'form.driverlicens': 'Körkort',
  'form.accountnumber': 'Kontonummer',
  'form.clearingnumber': 'Clearingnummer',
  'form.iban': 'IBAN',
  'form.swift': 'Swift',
  'form.recieveraccountname': 'Kontoansvarig',
  'form.employmenttype': 'Anställningstyp',
  'form.employmentdate': 'Anställningsdatum',
  'form.employeer': 'Arbetsgivare',
  'form.status': 'Status',
  'form.managername': 'Närmaste chef',
  'form.profession': 'Yrke',
  'form.id06': 'ID06',
  'form.absencestype': 'Typ av frånvaro',
  'form.phonenumber': 'Telefonnummer',
  'form.workphone': 'Arbetstelefon',
  'form.email': 'Email',
  'form.workemail': 'Arbetsmail',
  'form.role': 'Roll',
  'form.start': 'Start',
  'form.end': 'Slut',

  /* 05. Button */
  'button.save': 'Spara',
  'button.edit': 'Redigera',

  /* 06. topbar-nav */
  'topbar-nav.newabsence': 'Ny frånvaro',
  'topbar-nav.newsickleave': 'Ny sjukanmälan',
  'topbar-nav.myabsence': 'Mina ansökningar',
  'topbar-nav.createcompany': 'Skapa företag',
  'topbar-nav.allcompanies': 'Alla företag',
  'topbar-nav.allusers': 'Alla användare',

  /* 07. Company */
  'company.newcompany': 'Nytt bolag',
  'company.editcompany': 'Redigera bolag',
  'company.allcompanies': 'Alla bolag',

  /* 08. Validation */
  'validation.enter-start-date': 'Du har ej fyllt i något start datum',
  'validation.enter-start-end': 'Du har ej fyllt i något slut datum',

  /* 09. ProfessionDropdown */
  'profession.dropdown-concrete': 'Betongare',
  'profession.dropdown-plaster': 'Gipsare',
  'profession.dropdown-carpenter': 'Snickare',
  'profession.dropdown-framecompletion': 'Stomkomplettering',
  'profession.dropdown-constructionservice': 'Byggservice',
  'profession.dropdown-rivare': 'Rivare',
  'profession.dropdown-supervisor': 'Arbetsledare',
  'profession.dropdown-leadinginstaller': 'Ledande montör',
  'profession.dropdown-workmanager': 'Arbetschef',
  'profession.dropdown-offical': 'Tjänsteman',
  'profession.dropdown-cranedriver': 'Kranförare',
  'profession.dropdown-trainee': 'Praktikant',
  'profession.dropdown-painter': 'Målare',
  'profession.dropdown-welder': 'Svetsare',
  'profession.dropdown-suspension': 'Utsättare',
  'profession.dropdown-groundwork': 'Markarbete',
  'profession.dropdown-truckdriver': 'Lastbilsförare',
  'profession.dropdown-electrician': 'Elektriker',
  'profession.dropdown-pumpdriver': 'Pumpförare',
  'profession.dropdown-excavatoroperator': 'Grävmaskinsförare',
  'profession.dropdown-professionmissing': 'Yrke saknas',

  /* 10. Driverlicens dropdown */
  'driverlicens.dropdown-am': 'AM - moped klass I',
  'driverlicens.dropdown-a1': 'A1 – lätt motorcykel',
  'driverlicens.dropdown-a2': 'A2 - mellanstor motorcykel',
  'driverlicens.dropdown-a': 'A – tung motorcykel',
  'driverlicens.dropdown-b96': 'B96 - personbil och tyngre släp än vanligt B-körkort',
  'driverlicens.dropdown-b': 'B – personbil och lätt lastbil',
  'driverlicens.dropdown-be': 'BE – personbil med tungt släp',
  'driverlicens.dropdown-c1-c1e': 'C1 och C1E - medeltung lastbil',
  'driverlicens.dropdown-c-ce': 'C och CE – tung lastbil',
  'driverlicens.dropdown-d1-d1e': 'D1 och D1E - mellanstor buss',
  'driverlicens.dropdown-d-de': 'D och DE – buss',
  'driverlicens.dropdown-t': 'T - Traktor',
  'driverlicens.dropdown-tlp1': 'TLP 1 - Truck',
  'driverlicens.dropdown-none': 'Ingen',

  /* 11. Language dropdown */
  'language.dropdown-en': 'Engelska',
  'language.dropdown-ee': 'Estiska',
  'language.dropdown-fi': 'Finska',
  'language.dropdown-lv': 'Lettiska',
  'language.dropdown-lt': 'Litauiska',
  'language.dropdown-pl': 'Polska',
  'language.dropdown-de': 'Tyska',
  'language.dropdown-ru': 'Ryska',
  'language.dropdown-no': 'Norska',
  'language.dropdown-se': 'Svenska',
  'language.dropdown-ua': 'Ukrainska',

  'heading.user-information': 'Kontaktuppgifter',
  'heading.company-information': 'Arbetsgivaruppgifter',
  'heading.edit-user': 'Redigera användare',
  'heading.bankdetails': 'Bankuppgifter',
  // "heading.company-information": 'Arbetsgivaruppgifter',

  'Size.S': 'S',
  'Size.M': 'M',
  'Size.L': 'L',
  'Size.XL': 'XL',
  'Size.XXL': 'XXL',
};
