import React, { useContext, useEffect, useState } from 'react';
import { IAbsence } from '../../../interfaces/absenceInterface';
import { GetDropdownOptionsByLanguage, ValidateParentalLeaveForm, ValidateVacationForm } from './absenceHelper';
import IntlMessages from '../../../helpers/IntlMessages';
import { FormattedMessage } from 'react-intl';
import { Grid, InputLabel, MenuItem, Select, TextField, FormControl, Typography, Button, FormHelperText } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { GeneralContext, UserContext } from '../../../App';
import absenceService from '../../../services/absenceService';
import { getDefaultAbsence } from '../../../helpers/GetDefault';
import { IVacation } from '../../../interfaces/vacationInterface';
import AbscenceAtf from './absenceAtf';
import Abscence from './absence';
import AbscenceVab from './absenceVab';
import ModalLoader from '../../../components/common/ModalLoader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NotificationType } from '../../../components/common/NotificationBar';

const NewAbsence = () => {
  const User = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //TODO setmyvacation
  // eslint-disable-next-line
  const [MyVacation, setMyVacation] = useState<IVacation>({ paidVacation: 10, savedVacation: 10, unPaidVacation: 10 } as IVacation);
  const { id } = useParams();
  const [editItem, setAbsence] = useState<IAbsence>();
  const country = User ? (User.userState?.user?.companyId.toLocaleLowerCase() === 'C0CF5D0B-0569-43DC-B55F-08D88BCD5DED'.toLocaleLowerCase() ? 'norway' : 'sweden') : ''; //REFACTOR THIS
  const appContext = useContext(GeneralContext);
  const { control, handleSubmit, watch, reset } = useForm<IAbsence>({ defaultValues: getDefaultAbsence(User) });
  let navigate = useNavigate();

  const { pathname } = useLocation();

  const fetchAbsenceDataById = async () => {
    setIsLoading(true);
    absenceService
      .GetAbsenceById(id)
      .then(async (responseData) => {
        if (responseData) {
          setAbsence(responseData);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onSubmit = async (data: IAbsence) => {
    setIsLoading(true);
    let valid: any = {};
    switch (data.selectedCauseId) {
      case 2: //Semester
        valid = ValidateVacationForm(data, country, MyVacation.paidVacation);
        break;
      case 3: //Tjänstledighet
        valid.obj = data;
        break;
      case 4: //Föräldraledighet
        valid = ValidateParentalLeaveForm(data, country);
        break;
      case 5: //VAB
        valid.err = '';
        valid.obj = data;
        break;
      case 6: //ATF
        data.status = 2;
        data.end = data.start;
        valid.obj = data;
        break;
      case 7: //Permission
        valid.err = '';
        valid.obj = data;
        break;
      case 8: //Egenmelding
        break;
    }
    if (valid.err) {
      appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
      setTimeout(() => setIsLoading(false), 1000);
      return;
    }
    data = valid.obj;
    if (editItem?.id) {
      let updatedItem = await absenceService.UpdateAbsence(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.updatesuccess" />);
    } else {
      let updatedItem = await absenceService.AddAbsence(data);
      if (!updatedItem) {
        appContext.ShowNotification(NotificationType.error, <IntlMessages id="form.savefailure" />);
        setIsLoading(false);
        return;
      }
      appContext.ShowNotification(NotificationType.success, <IntlMessages id="form.yourapplicationhasnowbeenreceived" />);
    }
    pathname.includes('admin') ? navigate(`/app/admin/absence/absences`) : navigate(`/app/absence/myabsence`);
  };

  const onDelete = async () => {
    setIsLoading(true);
    let deletedItem = await absenceService.DeleteAbsence(id as string);
    if (!deletedItem) {
      appContext.ShowNotification(NotificationType.error, 'Failed to delete');
      setIsLoading(false);
      return;
    }
    appContext.ShowNotification(NotificationType.success, 'Successfully deleted');
    setIsLoading(false);
    reset();
  };

  const getContent = (selectedCauseId: number | undefined): JSX.Element => {
    switch (selectedCauseId) {
      case 2:
      case 3:
      case 4:
      case 7:
        return <Abscence watch={watch} control={control} selectedCauseId={selectedCauseId} />;
      case 6:
        return <AbscenceAtf control={control} selectedCauseId={selectedCauseId} />;
      case 5:
        return <AbscenceVab control={control} selectedCauseId={selectedCauseId} />;
      default:
        return <p></p>;
    }
  };
  useEffect(() => {
    if (id) {
      fetchAbsenceDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editItem && editItem.selectedCauseId) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 1000);
      reset(editItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem]);
  return (
    <>
      <ModalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid marginTop={5} borderRadius={3} container maxWidth="sm" justifyContent="center" alignItems="center" className="container-fluid color-white">
          <Grid item xs={12} sm={12} marginTop={2}>
            {editItem ? (
              <Typography>
                <IntlMessages id="form.editabsence" />
              </Typography>
            ) : (
              <Typography>
                <IntlMessages id="absence.headingnewabsence" />
              </Typography>
            )}
          </Grid>
          {id ? (
            <Grid item xs={12} sm={12} marginTop={2}>
              <Controller
                name={'title'}
                control={control}
                rules={{ required: 'error-required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? <IntlMessages id={error.message} /> : null}
                    value={value?.toString() || ''}
                    fullWidth
                    disabled
                    size="small"
                    label={<IntlMessages id="form.name" />}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={12} marginTop={2}>
            <Controller
              name={'selectedCauseId'}
              control={control}
              rules={{ required: 'error.selectcause' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="selectedCause">
                    <IntlMessages id="form.absencestype" />
                  </InputLabel>
                  <Select size="small" error={!!error} label={'Cause'} value={value || ''} onChange={onChange}>
                    {GetDropdownOptionsByLanguage(User.userState?.user?.companyId ?? '').map((p, index) => (
                      <MenuItem key={index} value={p.key}>
                        <FormattedMessage id={p.text} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>{error ? <IntlMessages id={error.message} /> : null}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          {getContent(watch('selectedCauseId'))}
          <Grid marginBottom={2} marginTop={2} container justifyContent="space-between">
            {id ? (
              <Button onClick={() => onDelete()} variant="contained" color="error">
                {<IntlMessages id="button.delete" />}
              </Button>
            ) : null}

            <Button onClick={() => (pathname.includes('admin') ? navigate(`/app/admin/absence/absences`) : navigate(`/app/absence/myabsence`))} variant="outlined">
              {<IntlMessages id="button.abort" />}
            </Button>
            <Button type="submit" variant="contained">
              {<IntlMessages id="button.save" />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default NewAbsence;
