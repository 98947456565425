import { GetUserWithValidToken } from '../helpers/Utils';
import { IRegisterUser, IUser } from '../interfaces/userInterface';
import { IFriendlyUser } from '../interfaces/authInterface';

export class UserService {
  AddUser = async (newUser: IUser) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/user/createabsence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(newUser),
    });
    return response;
  };

  GetUserById = async (id: string = '') => {
    const user = await GetUserWithValidToken();
    const response = await fetch(`/user/getbyid/${id}/` + true, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    let responseData: IUser = await response.json();
    return responseData;
  };

  EditUser = async (editUser: IUser) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/user/edituser/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(editUser),
    });
    if (response.ok) {
      return await response.json();
    }
    return null;
  };

  GetAllUsers = async (): Promise<IFriendlyUser[]> => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/user/getallusers', {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      let responseData = await response.json();
      return responseData;
    } else {
      console.log();
      return [];
    }
  };
  public async GetUserRoles() {
    let url = `/user/getuserroles`;
    const user = await GetUserWithValidToken();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      let responseData = await response.json();
      return responseData;
    } else {
      console.log();
      return [];
    }
  }
  CreateUserAsync = async (newUser: IRegisterUser) => {
    const user = await GetUserWithValidToken();
    return await fetch('/user/registeruser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(newUser),
    });
  };

  DeleteUser = async (id: string = '') => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/user/delete/' + id, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response?.ok) {
      return true;
    } else {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
  };

  async updateEmail(state: any) {
    const user = await GetUserWithValidToken();
    try {
      const response = await fetch('/user/updateemail', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(state),
      });
      var textResponse = await response.text();
      if (textResponse === 'Password was Updated') {
        return response;
      } else {
        return response;
      }
    } catch (e) {
      return e;
    }
  }
  SendResetPasswordEmail = async (email: string) => {
    // const user = await GetUserWithValidToken();
    return await fetch(`/auth/forgotpasswordasync/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

    // GetAllUsersByCompany = async () => {
    //     const user = await GetUserWithValidToken();
    //     return await fetch('/user/getallusersbycompany/' + company.CompanyId , {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${user.token}`
    //         }
    //     });
    // }
    SendResetPasswordEmailToAllUsers = async (emails: (string | undefined)[]) => { 
      // const user = await GetUserWithValidToken();
      return await fetch(`/auth/sendresetpasswordemailtoallusers/${emails}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emails),
      });
    }
  }
 
  const userService = new UserService();

export default userService;
