import { GetUserWithValidToken } from '../helpers/Utils';
import { ICompany } from '../interfaces/companyInterface';

export class CompanyService {
  AddCompany = async (newCompany: ICompany) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/company/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(newCompany),
    });
    return response;
  };
  GetCompanyById = async (companyId: string = '') => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/company/get/' + companyId, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    let responseData: ICompany = await response.json();
    return responseData;
  };

  GetCompanies = async () => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/company/getalladmin', {
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      let responseData = await response.json();
      return responseData;
    } else {
      console.log();
      return [];
    }
  };

  UpdateCompany = async (company: ICompany) => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/company/update', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(company),
    });
    return response;
  };

  DeleteCompany = async (id: string = '') => {
    const user = await GetUserWithValidToken();
    const response = await fetch('/company/delete/' + id, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${user.token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response?.ok) {
      return true;
    } else {
      return response.text().then((text) => {
        throw new Error(text);
      });
    }
  };
}
const companyService = new CompanyService();

export default companyService;
