import IntlMessages from '../../../helpers/IntlMessages';
import React from 'react';
import { Box, Grid, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import MyAccountEmail from './myAccountEmail';
import MyAccountPassword from './myAccountPassword';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const MyAccount = () => {
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [valueTab, setValues] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValues(newValue);
  };

  return (
    <>
      <Grid mt={2} container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: { xs: 240, sm: 400 },
            bgcolor: 'background.paper',
          }}
        >
          <Tabs
            value={valueTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile={true}
            aria-label="scrollable force tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 1 },
              },
            }}
          >
            <Tab label={<IntlMessages id="user.changeemail" />} />
            <Tab label={<IntlMessages id="user.changepassword" />} />
          </Tabs>
        </Box>
      </Grid>

      <TabPanel value={valueTab} index={0}>
        <MyAccountEmail />
      </TabPanel>

      <TabPanel value={valueTab} index={1}>
        <MyAccountPassword />
      </TabPanel>
    </>
  );
};

export default MyAccount;
