import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export enum NotificationType {
  none,
  error,
  warning,
  info,
  success,
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type AddIconProps = {
  typeOfNotification: NotificationType;
  message?: ReactJSXElement;
  closeNotification(): void;
};

export const NotificationBar = ({ typeOfNotification, message, closeNotification }: AddIconProps) => {
  const [open, setOpen] = useState(true);

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    closeNotification();
    setOpen(false);
  };

  const getNotificationBar = () => {
    switch (typeOfNotification) {
      case NotificationType.error:
        return (
          <Snackbar autoHideDuration={30000} open={open} onClose={() => handleClose()}>
            <Alert onClose={() => handleClose()} severity="error">
              {message}
            </Alert>
          </Snackbar>
        );
      case NotificationType.warning:
        return (
          <Snackbar autoHideDuration={30000} open={open} onClose={() => handleClose()}>
            <Alert onClose={() => handleClose()} severity="warning">
              {message}
            </Alert>
          </Snackbar>
        );
      case NotificationType.info:
        return (
          <Snackbar autoHideDuration={30000} open={open} onClose={() => handleClose()}>
            <Alert onClose={() => handleClose()} severity="info">
              {message}
            </Alert>
          </Snackbar>
        );
      case NotificationType.success:
        return (
          <Snackbar autoHideDuration={30000} open={open} onClose={() => handleClose()}>
            <Alert onClose={() => handleClose()} severity="success">
              {message}{' '}
            </Alert>
          </Snackbar>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, typeOfNotification]);

  return <>{getNotificationBar()}</>;
};
